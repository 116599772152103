.projects {

    &__wrapper {
        max-width: 1688px;
        padding: 0 36px;
        margin: 0px auto;
        display: flex;
        flex-direction: column;
        overflow: hidden;
        transition: 0.3s;
    }

    &__row {
        display: flex;
    }

    &__heading {
        width: 100%;
    }

    &__nav {
        width: 100%;
        margin-left: 90px;
    }

    &__p {
        font-family: $HurmeGeometricSans1-Rg;
        font-size: rem(16);
        line-height: rem(32);
        font-weight: normal;
        letter-spacing: rem(1.12);
        max-width: 788px;
        color: #4D4D4D;
        opacity: 1;
        transform: translateY(0);
        transition: transform 0.8s ease-out 0.3s, opacity 0.8s ease-out 0.3s;
    }

    &__backArrow {
        display: flex;
        margin-top: 54px;
        opacity: 1;
        transform: translateY(0);
        transition: 0.5s ease-out 1s;

        svg {
            transition: .3s;
        }

        svg:hover {
            transform: translateX(-10px);
        }
    }

    &__breadcrumbs {
        display: flex;
        flex-wrap: wrap;
        flex-direction: column-reverse;
        justify-content: space-between;
        flex-direction: row;
        padding-right: 6px;
        opacity: 1;
        transform: translateY(0);
        transition: transform 1s ease-out 0.6s, opacity 1s ease-out 0.6s;
    }

    .small-arrow {
        opacity: 0;
        transition: 0.3s;
    }

    &__pages {
        display: flex;

        li {
            position: relative;
            margin-right: 30px;

            a {
                transition: .3s ease-out;
                border-bottom: 0px solid transparent;
            }

            a:hover {
                border-bottom: 1px solid #000;
            }

            &:nth-child(1n):after {
                content: '/';
                display: block;
                position: absolute;
                top: 0px;
                left: -16px;
            }

            &:first-child:after {
                content: '';
                display: none;
            }

            &:last-child {
                margin-right: 0;
            }
        }

    }

    &__page {
        font-family: $HurmeGeometricSans1-Rg;
        font-size: rem(11);
        font-weight: normal;
        letter-spacing: rem(0.77);
        text-transform: uppercase;
        color: #071110;
        line-height: rem(20);
    }

    &__page--active {
        font-family: $HurmeGeometricSans1-Bold;
        font-weight: bold;
        line-height: rem(21);
    }

    &__secondNav {
        width: 100%;
        margin: 30px 0 40px 90px;
        display: flex;
        justify-content: space-between;
        font-family: $HurmeGeometricSans1-Rg;
        font-size: rem(10);
        font-weight: normal;
        letter-spacing: rem(2.8);
        text-transform: uppercase;
        color: #808080;

        a {
            transition: 0.3s;
        }

        a:hover {
            color: #000;
        }
    }

    &__filter {
        display: flex;
        flex-wrap: wrap;
        transition: transform 1s ease-out 0.6s, opacity 1s ease-out 0.6s;

        a {
            margin-left: 10px;
            transition: color 0.4s;
        }

        span {
            transition: transform 0.4s;
        }

        li {
            line-height: rem(40);
            margin-right: 32px;
        }

        li:first-child span {
            margin-left: 0;
        }

        li:hover {
            span {
                transform: rotate(-45deg);
                border-color: $orange;
            }

            a {
                color: $orange;
            }
        }
    }

    &__filterItem--active {
        font-family: $HurmeGeometricSans1-Bold;
        font-weight: bold;
        color: #000;
    }

    &__black-border {
        display: inline-block;
        width: 40px;
        max-width: 40px;
        margin-bottom: 4px;
        border-bottom: 1px solid #000;
        transition: opacity, width, max-width 0.3s;
    }

    &__list {
        max-width: 2560px;
        margin: 0 auto;
        width: 100%;
        margin-bottom: 260px;
    }
    
    &__listWrap {
        margin-top: 0;
    }

    // Project

    &__project {
        margin-bottom: 60px;

        .box {
            width: 100%;
            height: 100%;
            position: relative;
            outline: 4px solid #000;
            outline-offset: -4px;
            padding: 43px 12px 12px 80px;
        }

        .projects__img {
            display: block;
            position: relative;
            width: 460px;
            height: 300px;
            background-image: url('https://eurohouse.ca/images/pages/projects.html/8953d82f4630cf29b9a83efbe499efe5.jpg');
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center;
            transition: .3s;
        }

        .projects__info {
            position: absolute;
            top: 29%;
            right: -55%;
            width: 300px;
            height: 176px;
        }

        .projects__name {
            position: absolute;
            font-family: $Roboto-Thin;
            font-weight: 100;
            font-size: rem(24);
            line-height: rem(28);
            letter-spacing: rem(2);
            text-transform: uppercase;
            margin-top: 20px;
    
            &:after {
                content: '';
                display: block;
                width: 40px;
                height: 1px;
                background: #000;
                position: absolute;
                top: -20px;
            }
        }

        .projects__hidenName {
            opacity: 0;
            visibility: hidden;
            transition: .3s;
        }
    }

    &__projectReverse {
        display: flex;
        justify-content: flex-end;
        margin-bottom: 60px;

        .box {
            width: 100%;
            height: 100%;
            position: relative;
        }
        
        .projects__img {
            display: block;
            width: 600px;
            height: 384px;
            background-image: url('https://eurohouse.ca/images/pages/projects.html/8b6b906367d845d9c4c07d54ae70aabe.jpg');
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center;
            transition: .3s;
        }

        .projects__info {
            height: 100%;
            margin-top: 100px;
            margin-right: 20px;
            display: flex;
            flex-direction: column;
            justify-content: center;
        }

        .projects__name {
            position: relative;
            width: 370px;
            font-family: $Roboto-Thin;
            font-size: rem(44);
            line-height: rem(54);
            text-transform: uppercase;
            letter-spacing: rem(2);

            &:after {
                content: '';
                display: block;
                position: absolute;
                width: 40px;
                height: 1px;
                top: -30px;
                left: 0;
                background: #000;
            }
        }

        .projects__hidenName {
            opacity: 0;
            visibility: hidden;
        }

        .btn-transparent {
            display: inline-block;
            position: relative;
            height: 53px;
            width: 170px;
            margin-top: 30px;
            font-family: $FuturaDemic;
            font-size: rem(10);
            letter-spacing: rem(2);
            line-height: rem(53);
            text-align: center;
            text-transform: uppercase;
            color: #000;
            overflow: hidden;
        }
    }


    &.animate-visible {

        .projects__breadcrumbs {
            opacity: 0;
        }

        .projects__p {
            opacity: 0;
            transform: translateY(30px);
        }

        .projects__backArrow {
            opacity: 0;
        }

        .projects__filter {
            opacity: 0;
        }
    }

    $duration: 1s;

    .box {
        span {
            position: absolute;
            display: block;
            background: #EE7F1A;
            transition: all $duration/4 linear;
        }

        .top, .bottom {
            width: 100%;
            height: 4px;
            transform: scaleX(0);
        }

        .left, .right {
            width: 4px;
            height: 100%;
            transform: scaleY(0);
        }

        .top {
            top: 0;
            left: 0;
            transition-delay: $duration*3/4;
            transform-origin: top left;
        }

        .right {
            top: 0;
            right: 0;
            transition-delay: $duration/2;
            transform-origin: top left;
        }

        .bottom {
            bottom: 0;
            right: 0;
            transition-delay: $duration/4;
            transform-origin: top right;
        }

        .left {
            top: 0;
            left: 0;
            transform-origin: bottom left;
        }

    }

    &__project:hover {

        .top {
            transition-delay: 0s;
        }
        .right {
            transition-delay: $duration/4;
        }
        .bottom {
            transition-delay: $duration/2;
        }
        .left {
            transition-delay: $duration*3/4;
        }
        .top,
        .bottom {
            transform: scaleX(1);
        }
        .left,
        .right {
            transform: scaleY(1);
        }
    }

    &__projectReverse:hover {
        .top {
            transition-delay: 0s;
        }
        .right {
            transition-delay: $duration/4;
        }
        .bottom {
            transition-delay: $duration/2;
        }
        .left {
            transition-delay: $duration*3/4;
        }
        .top,
        .bottom {
            transform: scaleX(1);
        }
        .left,
        .right {
            transform: scaleY(1);
        }
    }

    .inner-box {
        position: relative;

        span {
            position: absolute;
            display: block;
            background: #EE7F1A;
            transition: all $duration/4 linear;
        }

        .top, .bottom {
            width: 100%;
            height: 4px;
            transform: scaleX(0);
        }

        .left, .right {
            width: 4px;
            height: 100%;
            transform: scaleY(0);
        }

        .top {
            top: 0;
            left: 0;
            transition-delay: $duration*3/4;
            transform-origin: top left;
        }

        .right {
            top: 0;
            right: 0;
            transition-delay: $duration/2;
            transform-origin: top left;
        }

        .bottom {
            bottom: 0;
            right: 0;
            transition-delay: $duration/4;
            transform-origin: top right;
        }

        .left {
            top: 0;
            left: 0;
            transform-origin: bottom left;
        }

        .projects__project--reverse:hover {
            background: red;
        }
    }

    .projects__project--reverse:hover {
        .top {
            transition-delay: 0s;
        }
        .right {
            transition-delay: $duration/4;
        }
        .bottom {
            transition-delay: $duration/2;
        }
        .left {
            transition-delay: $duration*3/4;
        }
        
        .top,
        .bottom {
            transform: scaleX(1);
        }
        .left,
        .right {
            transform: scaleY(1);
        }
    }



    
    @media only screen and (max-width: 1490px) {
        &__wrapper {
            max-width: 1412px;
        }
    }
    
    @media only screen and (max-width: 1280px) {
        &__black-border {
            width: 34px;
            max-width: 34px;
        }

        &__list {
            margin-bottom: 160px;
        }
    }
    
    @media only screen and (max-width: 1110px) {
    
        &__wrapper {
            padding: 0 50px;
        }
    
        &__backArrow {
            display: none;
        }
    
        &__nav, &__secondNav {
            margin-left: 0;
        }
    
        &__breadcrumbs {
            margin-bottom: 60px;
    
            svg {
                margin-top: 2px;
                transition: 0.3s;
    
                &:hover {
                    transform: translateX(-10px);
                }
            }
    
            .small-arrow {
                opacity: 1;
            }
        }
    }
    
    @media only screen and (max-width: 1000px) {

        &__filter {
            justify-content: flex-start;
            width: 100%;

            span {
                width: 0;
                margin: 0;
                line-height: 40px;
            }
    
            a {
                margin-left: 0;
            }
        }
    }

    @media only screen and (max-width: 820px) {

        &__listWrap {
            padding: 0 30px;
        }

        .projects__info {
            display: none;
        }

        .projects__project {
            margin-bottom: 30px;
        }

        .projects__projectReverse {
            margin-bottom: 30px;
        }

        .projects__project .projects__img {
            width: 100%;
            height: 500px;
            display: flex;
            flex-direction: row;
            align-items: flex-end;

            &:before {
                content: '';
                display: block;
                width: 100%;
                height: 100%;
                position: absolute;
                top: 0;
                left: 0;
                background: rgba(0, 0, 0, 0.22);
            }
        }

        .projects__projectReverse .projects__img {
            width: 100%;
            height: 500px;
            position: relative;            

            &:before {
                content: '';
                display: block;
                width: 100%;
                height: 100%;
                position: absolute;
                top: 0;
                left: 0;
                background: rgba(0, 0, 0, 0.22);
            }
        }

        .projects__hidenName {
            opacity: 1;
            visibility: visible;
            color: #fff;
            font-family: $Roboto-Thin;
            font-size: rem(50);
            text-transform: uppercase;
            padding: 60px 46px 60px 46px;
            width: 100%;
            z-index: 100;
            transition: .3s;
        }

        .box {
            display: flex;
            flex-direction: row;
            align-items: flex-end
        }
    }

    
    @media only screen and (max-width: 720px) {    
        &__filter {
            li {
                margin-right: 20px;
            }
        }

        &__list {
            margin-bottom: 100px;
        }

        .projects__hidenName {
            font-size: rem(48);
        }

        .projects__projectReverse .projects__img, .projects__project .projects__img {
            height: 400px;
        }
    }

    @media only screen and (max-width: 649px) {
        .projects__projectReverse .projects__img, .projects__project .projects__img {
            height: 340px;
        }

        .projects__hidenName {
            font-size: rem(42);
            padding: 46px;
        }
    }
    
    @media only screen and (max-width: 590px) {    
        &__pages {
            justify-content: flex-end;
        }

        .projects__hidenName {
            font-size: rem(36);
        }
    }
    
    @media only screen and (max-width: 540px) {
        &__p {
            font-size: rem(14);
            line-height: rem(32);
            letter-spacing: rem(0.48);
        }
    
        &__secondNav {
            margin-bottom: 30px;
        }

        &__filter {
            li {
                margin-right: 16px;
            }
        }

        &__listWrap {
            padding: 0 20px;
        }

        .projects__projectReverse, .projects__project {
            margin-bottom: 20px;
        }

        .projects__projectReverse .projects__img, .projects__project .projects__img {
            height: 300px;
        }

        .projects__hidenName {
            font-size: rem(32);
            padding: 36px;
        }
    }
    
    @media only screen and (max-width: 519px) {

        &__breadcrumbs {
            padding-right: 0;
        }
    }
    
    @media only screen and (max-width: 500px) {
        &__filter {
            width: 100%;
            font-size: rem(10);
            letter-spacing: rem(2);
        }
    }
    
    @media only screen and (max-width: 429px) {
        &__wrapper {
            padding: 0 36px;
        }

        &__listWrap {
            padding: 0 16px;
        }

        &__p {
            font-size: rem(12);
            line-height: rem(32);
            letter-spacing: rem(0.48);
        }

        .projects__projectReverse, .projects__project {
            margin-bottom: 16px;
        }

        .projects__projectReverse .projects__img, .projects__project .projects__img {
            height: 260px;
        }

        .projects__hidenName {
            font-size: rem(28);
            padding: 32px;
        }
    }
    
    @media only screen and (max-width: 400px) {
        &__page {
            font-size: rem(10);
    
            &--active {
                line-height: rem(20);
            }
        }
    }

    @media only screen and (max-width: 375px) {
        .projects__projectReverse .projects__img, .projects__project .projects__img {
            height: 240px;
        }
    }
    
    @media only screen and (max-width: 320px) {
        &__wrapper {
            padding: 0 24px;
        }

        &__listWrap {
            padding: 0 10px;
        }

        .projects__projectReverse .projects__img, .projects__project .projects__img {
            height: 220px;
        }

        .projects__projectReverse, .projects__project {
            margin-bottom: 10px;
        }

        .projects__hidenName {
            font-size: rem(22);
            padding: 24px;
        }
    }
}

.btn-transparent {
    height: 53px;
    line-height: 53px;
    text-align: center;
    font-size: 10px;
    letter-spacing: 2px;
    font-family: futura-demic-reg, Arial, sans-serif;
    text-transform: uppercase;
    position: relative;
    padding: 0 39px;
    display: inline-block;
    color: #000;
    overflow: hidden;
    outline: 0;
    transition-property: color, background;
    transition-duration: .4s;
    transition-timing-function: ease;
    border: 0;
    background: 0 0;
}

@media only screen and (max-width:767px) {
    .btn-transparent {
        height: auto;
        box-sizing: border-box;
        min-height: 53px;
        padding: 20px 39px;
        line-height: 13px;
    }
}

.btn-transparent .btn-line-1,
.btn-transparent .btn-line-3 {
    position: absolute;
    width: 0;
    height: 2px;
    background: #000;
}

.btn-transparent .btn-line-2,
.btn-transparent .btn-line-4 {
    position: absolute;
    height: 0;
    width: 2px;
    background: #000;
}

.btn-transparent .btn-line-1 {
    transition: background .4s ease, width .4s ease-out;
    left: 0;
    top: 0;
}

.touch .btn-transparent .btn-line-1 {
    width: 100%;
}

.btn-transparent .btn-line-2 {
    transition: background .4s ease, height .4s ease-out .4s;
    right: 0;
    top: 0;
}

.touch .btn-transparent .btn-line-2 {
    height: 100%;
}

.btn-transparent .btn-line-3 {
    transition: background .4s ease, width .4s ease-out .8s;
    bottom: 0;
    right: 0;
}

.touch .btn-transparent .btn-line-3 {
    width: 100%;
}

.btn-transparent .btn-line-4 {
    transition: background .4s ease, height .4s ease-out 1.2s;
    left: 0;
    bottom: 0;
}

.touch .btn-transparent .btn-line-4 {
    height: 100%;
}

.btn-transparent.inview .btn-line-1,
.btn-transparent.inview .btn-line-3 {
    width: 100%;
}

.btn-transparent.inview .btn-line-2,
.btn-transparent.inview .btn-line-4 {
    height: 100%;
}

.btn-transparent.white {
    color: #fff !important;
}
.btn-transparent.white span {
    color: #fff !important;
}

.btn-transparent.white .btn-line-1,
.btn-transparent.white .btn-line-2,
.btn-transparent.white .btn-line-3,
.btn-transparent.white .btn-line-4 {
    background: #fff;
}

.btn-transparent .hover {
    position: absolute;
    display: block;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: #EE7F1A;
    color: #fff;
    z-index: 4;
    transform: translate(0, 100%);
    opacity: 0;
    animation: hoverOut .3s ease 1 forwards;
}

@media only screen and (max-width:767px) {
    .btn-transparent .hover {
        padding: 20px 39px;
        line-height: 13px;
        box-sizing: border-box;
    }
}

.touch .btn-transparent .hover {
    display: none;
}

.btn-transparent .hover_i {
    position: relative;
    display: inline-block;
    transform: translate(0, 10px);
    transition: transform .3s ease-out;
}

.btn-transparent .hover-out {
    position: relative;
    z-index: 1;
    display: inline-block;
    animation: hoverOutInner .3s ease 1 forwards;
}

.btn-transparent:hover .hover-out {
    animation-name: hoverInInner;
}

.btn-transparent:hover .hover {
    animation-name: hoverIn;
}

.btn-transparent:hover .hover_i {
    transition-delay: .2s;
    transform: translate(0, 0);
}

.btn-transparent.active .hover-out {
    animation-name: hoverInInner;
}

.btn-transparent.active .hover {
    animation-name: hoverIn;
}

.btn-transparent.active .hover {
    display: block;
}

.btn-transparent.active .hover_i {
    transition-delay: .2s;
    transform: translate(0, 0);
}

.btn-transparent.btn-transparent_border-black .btn-line-1 {
    width: 100%;
    background: #000;
}

.btn-transparent.btn-transparent_border-black .btn-line-2 {
    height: 100%;
    background: #000;
}

.btn-transparent.btn-transparent_border-black .btn-line-3 {
    width: 100%;
    background: #000;
}

.btn-transparent.btn-transparent_border-black .btn-line-4 {
    height: 100%;
    background: #000;
}

@keyframes hoverOut {
    0% {
        transform: translate(0, 0);
        opacity: 1;
    }
    100% {
        transform: translate(0, -100%);
        opacity: 0;
    }
}

@keyframes hoverIn {
    0% {
        transform: translate(0, 100);
        opacity: 0;
    }
    100% {
        transform: translate(0, 0);
        opacity: 1;
    }
}

@keyframes hoverOutInner {
    0% {
        transform: translate(0, 30px);
        opacity: 0;
    }
    100% {
        transform: translate(0, 0);
        opacity: 1;
    }
}


@keyframes hoverInInner {
    0% {
        transform: translate(0, 0);
        opacity: 1;
    }
    100% {
        transform: translate(0, -30px);
        opacity: 0;
    }
}