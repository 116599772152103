.values {
    padding: 0 36px;

    &__wrapper {
        max-width: 1340px;
        margin: 0 auto;
        transition: 0.3s;
    }

    &__row {
        @include row-flex();
    }

    &__item {
        @include col();
        @include size(4);
        transition: 1.3s ease-out;
        opacity: 1;

        &:first-child {
            padding-right: 60px;

            .values__name, .values__text {
                transition: opacity 1s ease-out 0.5s, transform 1s ease-out 0.5s;
            }
        }

        &:nth-child(2) {

            .values__name, .values__text {
                transition: opacity 1s ease-out 0.5s, transform 1s ease-out 0.5s;
            }
        }

        &:last-child {
            padding-left: 60px;

            .values__name, .values__text {
                transition: opacity 1s ease-out 0.5s, transform 1s ease-out 0.5s;
            }
        }

        &.animate-visible {
    
            .values__name {
                opacity: 0;
                transform: translateY(30px);
            }
    
            .values__text {
                opacity: 0;
                transform: translateY(30px);
            }
        }
    }

    &__icon {
        display: block;
        width: 100px;
        margin: 0 auto;
        text-align: center;
    }

    &__name {
        font-family: $HurmeGeometricSans1-Bold;
        font-size: rem(20);
        font-weight: bold;
        line-height: rem(24);
        letter-spacing: rem(2);
        text-align: center;
        text-transform: uppercase;
        margin-top: 30px;
        transform: translateY(0);
    }

    &__text {
        font-family: $HurmeGeometricSans1-Rg;
        font-size: rem(16);
        font-weight: normal;
        line-height: rem(28);
        letter-spacing: rem(0.6);
        margin-top: 26px;
        transform: translateY(0);
    }

    @media only screen and (max-width: 1119px) {
        &__item {
            width: 100%;
            max-width: 600px;
            margin: 0 auto 80px auto;
    
            &:first-child {
                padding-right: 0;
            }
    
            &:last-child {
                padding-left: 0;
                margin-bottom: 0;
            }
        }
    }
    
    @media only screen and (max-width: 419px) {
        &__item {
            margin: 0 auto 48px auto;
        }
    
        &__name {
            font-size: rem(16);
            line-height: rem(24);
            letter-spacing: rem(1.6);
        }
    
        &__text {
            font-size: rem(12);
            line-height: rem(24);
            letter-spacing: rem(0.5);
        }
    }
    
    @media only screen and (max-width: 320px) {
        & {
            padding: 0 24px;
        }   
    }
}