.numbers {
    max-width: 1500px;
    text-align: center;
    margin: 120px auto 130px auto;
    padding: 0 30px;

    &__row {
        @include row-flex();
    }

    &__item {
        display: flex;
        flex-direction: column;
        @include col();
        @include size(3);
    }

    &__value {
        font-family: $HurmeGeometricSans1-SemiBold;
        font-size: rem(92);
        font-weight: bold;
        color: #000;
    }

    &__name {
        margin-top: 20px;
        font-family: $HurmeGeometricSans1-Rg;
        font-size: rem(20);
        font-weight: normal;
        line-height: rem(24);
        letter-spacing: rem(2);
        text-transform: uppercase;
        color: #636262;
    }

    @media only screen and (max-width: 1590px) {
        & {
            padding: 0;
        }
    }
    
    @media only screen and (max-width: 1430px) {
        &__value {
            font-size: rem(72);
        }
    }
    
    @media only screen and (max-width: 1099px) {
        &__item {
            @include size(6);
    
            &:first-child, &:nth-child(2)  {
                margin-bottom: 90px;
            }
        }
    }
    
    @media only screen and (max-width: 768px) {
        & {
            margin-bottom: 20px;
        }
        
        &__item {
            @include size(12);
            margin-bottom: 90px;
        }
    }
    
    @media only screen and (max-width: 320px) {
        &__value {
            font-size: rem(64);
        }
    
        &__name {
            font-size: rem(18);
        }
        
        &__item {
            margin-bottom: 80px;
    
            &:first-child, &:nth-child(2)  {
                margin-bottom: 80px;
            }
        }
    }
}
