@import "modules/common.blocks/white-border";
@import "modules/common.blocks/home-btn";

@import "modules/header/header";
@import "modules/header-black/header-black";
@import "modules/footer/footer";
@import "modules/pre-footer/pre-footer";
@import "modules/values/values";
@import "modules/numbers/numbers";
@import "modules/instagram-widget/instagram-widget";
@import "modules/send-request/send-request";
@import "modules/form/form";
@import "modules/our-projects/our-projects";

@import "modules/home/home";
@import "modules/pages/about-us.html-us/pages/about-us.html-us";
@import "modules/our-partners/our-partners";
@import "modules/pages/contact.html/pages/contact.html";
@import "modules/pages/projects.html/pages/projects.html";
@import "modules/project/project";
@import "modules/tag/tag";
@import "modules/not-found/not-found";