.our-projects {
    position: absolute;
    top: 0;
    right: 30px;
    height: 100%;
    width: 1px;
    padding-left: 5px;
    border-right: 1px solid rgba(255, 255, 255, .4);
    transition: .4s;
    z-index: 890;

    &__wrapper {
        z-index: 2000;
        position: relative;
        top: 0;
        height: 100%;
        overflow-y: auto;
        &::-webkit-scrollbar {
            background: none;
            width: 0;
        }

        &::-webkit-scrollbar-track {
            box-shadow: none;
        }

        &::-webkit-scrollbar-thumb {
            background-color: none;
            outline: 0 solid transparent;
        }
    }
    
    &__menu {
        z-index: 2000;
        overflow: hidden;
    }

    &__title {
        position: absolute;
        top: 220px;
        left: 0px;
        padding-right: 10px;
        transform: translateX(-100%);
        font-family: Arial, sans-serif;
        font-size: rem(10);
        letter-spacing: rem(3);
        text-transform: uppercase;
        color: #fff;
    }

    &__project {
        position: relative;
        display: block;
        width: 100%;
        height: 110px;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        visibility: hidden;
        // opacity: 0;
        transform: translateX(100%);

        &::before {
            content: '';
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            opacity: 0;
            transition: 0.4s;
            background: rgba(0, 0, 0, 0.4);
        }

        &:hover::before {
            opacity: 1;
        }
    }

    &__name {
        width: 100%;
        height: 100%;
        opacity: 0;
        transition: 0.4s;
        
        &:hover {
            background: rgba(0, 0, 0, 0.4);
            opacity: 1;
        }
    }

    &--open {
        width: 160px;
        padding-left: 0;
        background: #000;

        .our-projects__project {
            transition: .4s;
            visibility: visible;
            opacity: 1;
            transform: translateX(0);
        }
    }

    @media only screen and (max-width: 649px) {
        & {
            display: none;
        }
    }
}