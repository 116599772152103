.header--black {
    position: relative;

    .header__logo {
        display: inline-block;
        width: 208px;
        height: 68px;
        margin-top: 14px;
        margin-right: 14px;
        background: url('../img/logo/logo-black.png') no-repeat;
        background-size: contain;
    }

    .header__menuBtn_icon {
        border: 1px solid rgba(0, 0, 0, .4);

        &:hover {
            border-color: #000;
        }
    }

    .header__menuBtn_line-1, .header__menuBtn_line-2, .header__menuBtn_line-3 {
        background: #000;
    }

    .header__menuBtn_title {
        color: #000;
    }

    .header__menu--open {

        .header__menuBtn_icon {
            border: 1px solid rgba(255, 255, 255, .4);

            &:hover {
                border-color: #fff;
            }
        }
    }

    @media only screen and (max-width: 578px) {
        
        .header__social {
            opacity: 1;
            height: auto;
        }
    }

    @media only screen and (max-width: 429px) {
        & {
            padding: 35px 10px 35px 20px;
        }
    }
}