.form {
    display: flex;
    flex-direction: column;

    &__request {
        position: relative;
        
        
        &:first-child, &:last-child {
            border-top: 1px solid #e8e8e8;
        }
    }

    &__label {
        position: absolute;
        width: 0;
        font-family: $FuturaDemic;
        font-size: rem(12);
        text-transform: uppercase;
        line-height: rem(50);
        letter-spacing: rem(2);
        text-align: right;
        height: 50px;
        color: #b4b4b4;
        z-index: 10;
        white-space: nowrap;
        transition: 0.5s ease-out;

        &--focused {
            width: 100%;
            color: #EE7F1A;
        }
    }

    &__inp {
        width: 100%;
        border: none;
        font-family: $FuturaDemic;
        font-size: rem(14);
        font-weight: bold;
        line-height: rem(50);
        letter-spacing: rem(2);
        border-bottom: 1px solid #e8e8e8;
        padding: 0 120px 0 0;

        &:focus {
            outline: none;
        }
    }

    &__message {
        padding: 20px 120px 5px 0;
        height: 70px;
        line-height: rem(14);
        overflow: auto;
        resize: none;
    }


    input::-webkit-contacts-auto-fill-button {
        visibility: hidden;
        display: none !important;
        pointer-events: none;
        position: absolute;
        right: 0;
    }

    &__wrap {
        position: relative;

        &:after {
            content: '';
            display: block;
            position: absolute;
            top: 4px;
            right: 10px;
            width: 10px;
            height: 19px;
            border: solid transparent;
            border-width: 0 2px 2px 0;
            transform: rotate(45deg);
            transform: scale(0);
            opacity: 0;
            transition: 0.3s;
        }
    }

    &__wrap--valid:after {
        content: '';
        display: block;
        position: absolute;
        top: 4px;
        right: 10px;
        width: 10px;
        height: 19px;
        border: solid black;
        border-width: 0 2px 2px 0;
        transform: rotate(45deg) scale(1);
        opacity: 1;
        transition: 0.3s;
    }

    &__submit, &__cancel {
        font-family: $HurmeGeometricSans1-Bold;
        font-size: rem(20);
        font-weight: bold;
        letter-spacing: rem(4);
        text-transform: uppercase;
    }

    &__submit {
        margin-top: 32px;
    }

    &__cancel {
        display: inline-block;
        position: relative;
        font-family: $FuturaDemic;
        font-size: rem(10);
        color: #000;
        text-transform: uppercase;
        padding-left: 60px;
        margin-top: 40px;
        line-height: rem(12);
        letter-spacing: rem(2);
        transition: color .5s;
        cursor: pointer;

        &:after {
            content: '';
            display: block;
            position: absolute;
            top: 4px;
            left: 0;
            width: 40px;
            height: 1px;
            background: #000;
            transform-origin: 50% 50%;
            transition: transform .5s, background .5s;
        }

        &:hover {
            color: #EE7F1A;

            &:after {
                transform: rotate(-45deg);
                background: #EE7F1A;
            }
        }
    }
}