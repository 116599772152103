.bg-black {
    background-color: #000;
}

.not-found {
    
    &__wrapper {
        max-width: 1688px;
        padding: 0 36px;
        margin: 230px auto 0px auto;
        display: flex;
        flex-direction: column;
        transition: 0.3s;
    }

    &__row {
        display: flex;
    }

    &__heading {
        width: 100%;
    }

    &__nav {
        width: 100%;
        margin-left: 90px;
    }

    &__backArrow {
        display: flex;
        margin-top: 76px;
        opacity: 1;
        transform: translateY(0);
        transition: 0.5s ease-out 1s;

        svg {
            transition: .3s;
        }

        svg:hover {
            transform: translateX(-10px);
        }
    }

    &__breadcrumbs {
        display: flex;
        flex-wrap: wrap;
        flex-direction: column-reverse;
        justify-content: space-between;
        flex-direction: row;
        padding-right: 10px;
        opacity: 1;
        transform: translateY(0);
        transition: transform 1s ease-out 0.6s, opacity 1s ease-out 0.6s;
    }

    .small-arrow {
        opacity: 0;
        transition: 0.3s;
    }

    &__pages {
        display: flex;

        li {
            position: relative;
            margin-right: 30px;

            a {
                transition: .3s ease-out;
                border-bottom: 0px solid transparent;
            }

            a:hover {
                border-bottom: 1px solid #fff;
            }

            &:nth-child(1n):after {
                content: '/';
                display: block;
                position: absolute;
                top: 0px;
                left: -16px;
            }

            &:first-child:after {
                content: '';
                display: none;
            }

            &:last-child {
                margin-right: 0;
            }
        }

    }

    &__page {
        font-family: $HurmeGeometricSans1-Rg;
        font-size: rem(11);
        font-weight: normal;
        letter-spacing: rem(0.77);
        text-transform: uppercase;
        color: #fff;
        line-height: rem(20);
    }

    &__page--active {
        font-family: $HurmeGeometricSans1-Bold;
        font-weight: bold;
        line-height: rem(21);
    }

    &__info {
        display: flex;
        margin-top: 34px;
    }

    &__img {
        height: 250px;
        width: 610px;
        min-width: 610px;
        background: url('../img/404.png') no-repeat;
        background-size: cover;
        background-position: center;
        transition: 0.3s;
    }

    &__text {
        max-width: 650px;
        margin-left: 80px;
        margin-top: 14px;
        z-index: 10;
    }

    &__h1 {
        font-family: $HurmeGeometricSans1-Bold;
        font-size: rem(20);
        line-height: rem(24);
        letter-spacing: rem(2);
        text-transform: uppercase;
        color: #fff;
        z-index: 10;
    }

    &__p {
        margin-top: 34px;
        font-family: $HurmeGeometricSans1-Rg;
        font-size: rem(32);
        line-height: rem(52);
        letter-spacing: rem(1.2);
        color: #B2B2B2;

        a {
            border-bottom: 2px solid #B2B2B2;
        }
    }

    &__homeBtn {
        display: block;
        width: 235px;
        margin: 120px auto 190px auto;
        padding: 18px 18px;
        border: 1px solid #fff;
        font-family: $HurmeGeometricSans1-Rg;
        font-size: rem(14);
        letter-spacing: rem(2.8);
        text-transform: uppercase;
        text-align: center;
        color: #fff;
        transition: 0.3s;

        &:hover {
            background: #fff;
            color: #000;
        }
    }

    &.animate-visible {
        .projects__breadcrumbs {
            opacity: 0;
        }

        .projects__backArrow {
            opacity: 0;
        }
    }
    
    @media only screen and (max-width: 1490px) {
        &__wrapper {
            max-width: 1412px;
        }
    }

    @media only screen and (max-width: 1240px) {
        &__img {
            width: 510px;
            min-width: 510px;
            height: 210px;
        }
    }
        
    @media only screen and (max-width: 1110px) {    
        &__wrapper {
            padding: 0 50px;
            margin-bottom: 40px;
        }
    
        &__backArrow {
            display: none;
        }
    
        &__nav {
            margin-left: 0;
        }
    
        &__breadcrumbs {
            margin-bottom: 90px;
    
            svg {
                margin-top: 2px;
                transition: 0.3s;
    
                &:hover {
                    transform: translateX(-10px);
                }
            }
    
            .small-arrow {
                opacity: 1;
            }
        }

        &__info {
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }

        &__text {
            margin-top: 80px;
            margin-left: 0;
            text-align: center;
        }

        &__homeBtn {
            margin-top: 80px;
            margin-bottom: 140px;
        }
    }
    
    @media only screen and (max-width: 1000px) {
        &__wrapper {
            margin-bottom: 40px;
        }
    }

    @media only screen and (max-width: 900px) {
        &__p {
            font-size: rem(26);
            line-height: rem(42);
        }
    }

    @media only screen and (max-width: 720px) {
        &__img {
            width: 400px;
            min-width: 400px;
            height: 162px;
        }

        &__p {
            font-size: rem(26);
            line-height: rem(42);
        }
    }
        
    @media only screen and (max-width: 590px) {
        &__wrapper {
            margin-top: 142px;
        }
    
        &__pages {
            justify-content: flex-end;
        }

        &__img {
            width: 302px;
            min-width: 302px;
            height: 122px;
        }

        &__text {
            margin-top: 60px;
        }

        &__p {
            font-size: rem(22);
            line-height: rem(36);
        }

        &__homeBtn {
            margin-top: 50px;
            margin-bottom: 100px;
        }
    }
    
    @media only screen and (max-width: 540px) {
        &__wrapper {
            margin-bottom: 0;
        }
    }
    
    @media only screen and (max-width: 519px) {
        &__breadcrumbs {
            padding-right: 0;
        }
    }
    
    @media only screen and (max-width: 429px) {
        &__wrapper {
            padding: 0 36px;
        }

        &__p {
            font-size: rem(16);
            line-height: rem(32);
            letter-spacing: rem(0.6);
        }

        &__back svg {
            transform: translateX(-16px);
        }

        &__homeBtn {
            width: 180px;
            padding: 14px;
            font-size: rem(12);
            letter-spacing: rem(2.4);
        }
    }
    
    @media only screen and (max-width: 400px) {
        &__page {
            font-size: rem(10);
    
            &--active {
                line-height: rem(20);
            }
        }
    }

    @media only screen and (max-width: 345px) {
        &__img {
            width: 260px;
            min-width: 260px;
            height: 106px;
        }
    }
    
    @media only screen and (max-width: 320px) {
        &__wrapper {
            padding: 0 24px;
        }
    }
}