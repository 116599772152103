.bg-darkest {
    background: $darkest;
}

.contact {
    background: $darkest;
    transition: all 1s;
    
    h4 {
        margin-bottom: 20px;
        font-family: $HurmeGeometricSans1-Bold;
        font-size: rem(16);
        line-height: rem(24);
        letter-spacing: rem(1.6);
        text-transform: uppercase;
        font-weight: bold;
    }

    ul {
        margin-left: 32px;
    }

    &__row {
        padding-left: 15px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        z-index: 100;
    }

    &__container {
        display: flex;
        max-width: 1688px;
        padding: 0 36px;
        margin: 200px auto -70px auto;
    }
    
    &__wrapper {
        width: 100%;
        margin-left: 54px;
        transition: 0.3s;
    }

    &__back {
        padding-top: 18px;
        transition: 0.3s;
    }

    &__breadcrumbs {
        color: #fff;
        height: 0;
        opacity: 1;
        transform: translateY(0);
        transition: transform 1s ease-out 0.6s, opacity 1s ease-out 0.6s;
    }
    
    &__pages {
        opacity: 0;
        margin-left: 0!important;
        justify-content: flex-end;
        margin-bottom: 30px;
        transition: opacity 0.3s;
    
        li {
            position: relative;
            margin-right: 30px;

            a {
                transition: .3s ease-out;
                border-bottom: 0px solid transparent;
            }

            a:hover {
                border-bottom: 1px solid #000;
            }

            &:nth-child(1n):after {
                content: '/';
                display: block;
                position: absolute;
                top: 0px;
                left: -16px;
            }

            &:first-child:after {
                content: '';
                display: none;
            }

            &:last-child {
                margin-right: 0;
            }
        }

    }

    &__page {
        font-family: $HurmeGeometricSans1-Rg;
        font-size: rem(11);
        font-weight: normal;
        letter-spacing: rem(0.77);
        text-transform: uppercase;
        color: #fff;
    }

    &__page--active {
        font-family: $HurmeGeometricSans1-Bold;
        font-weight: bold;
    }

    &__whiteArrow {
        opacity: 1;
        transform: translateY(0);
        transition: 0.5s ease-out 1s;
    
        svg {
            transition: .3s;
        }

        svg:hover {
            transform: translateX(-10px);
        }
    }

    &__address {
        display: flex;
        flex-wrap: wrap;
        margin: 24px 30px 0 0;
        justify-content: space-between;
        width: 926px;
        padding: 98px 138px 90px 98px;
        background: #fff;
        font-family: $HurmeGeometricSans1-Rg;
        font-size: rem(16);
        line-height: 32px;
        letter-spacing: rem(0.6);
        transition: .5s;
        z-index: 10;

        a {
            border-bottom: 0px solid transparent;
            transition: .3s ease-out;
    
            &:hover {
                border-bottom: 1px solid #000;
            }
        }
    }

    &__item {
        display: flex;
        align-items: end;
    }

    &__leftSide {
        display: flex;
        flex-direction: column;
    }

    &__office {
        margin-top: 90px;
    }

    &__showroom {
        height: 100%;

        ul {
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
        }
    }
    
    &__phone {
        font-family: $HurmeGeometricSans1-Bold;
        font-size: rem(18);
        font-weight: bold;
        line-height: rem(32);
        letter-spacing: rem(3.6);
    }

    &__days {
        font-family: $HurmeGeometricSans1-Bold;
        font-weight: bold;
        line-height: rem(24);
        letter-spacing: rem(1.6);
    }

    &__instagram {
        display: flex;
        align-items: center;
        margin-bottom: 62px;
        transition: 0.3s;
    }

    &__black-border {
        display: inline-block;
        width: 44px;
        max-width: 44px;
        margin-top: 10px;
        border-bottom: 2px solid #000;
    }

    &__map {
        height: 600px;
        max-width: 1616px;
        margin: 0 auto;
        background: #333;
        transition: .3s;
    }

    &.animate-visible {

        .contact__whiteArrow {
            opacity: 0;
        }

        .contact__breadcrumbs {
            opacity: 0;
        }
    }

    @media only screen and (max-width: 1590px) {
        &__address {
            width: 850px;
            padding-right: 98px;
        }
    }
    
    @media only screen and (max-width: 1490px) {
        &__container {
            max-width: 1412px;
        }
    
        &__address {
            padding-left: 68px;
            padding-right: 108px;
            width: 750px;
        }
    }
    
    @media only screen and (max-width: 1410px) {
        &__container {
            margin-bottom: 0;
        }
    
        &__wrapper {
            padding-right: 36px;
        }
    
        &__row {
            flex-direction: column;
            margin-bottom: 0;
        }
    
        &__instagram {
            margin: 50px 0 50px 0;
        }
    
        &__address {
            padding-right: 68px;
            margin-right: 0;
        }
    
        &__map {
            height: 500px;
        }
    }
    
    @media only screen and (max-width: 1000px) {
        &__address {
            width: 100%;
            padding: 68px;
        }
    
        &__item {
            flex-direction: column;
    
            ul {
                margin: 15px 0 0 0;
            }
        }
    
        &__map {
            height: 400px;
        }
    }
    
    @media only screen and (max-width: 820px) {
    
        &__back {
            padding-top: 25px;
        }
    
        &__address {
            padding: 55px 50px 45px 50px;
        }
    }
    
    @media only screen and (max-width: 790px) {
        &__wrapper {
            margin: 0;
            padding: 0 30px;
        }
    
        &__breadcrumbs {
            height: auto;
        }
    
        &__pages {
            display: flex;
            opacity: 1;
        }
    
        &__row {
            padding-left: 0;
        }
    
        &__address {
            flex-direction: column;
        }
    
        &__days {
            margin-top: 28px;
        }
    
        &__item {
            margin-top: 0;
            margin-bottom: 32px;
        }
    
        &__back {
            svg {
                width: 0;
            }
    
            opacity: 0;
            width: 0;
        }
    
        &__black-border {
            width: 36px;
            max-width: 36px;
        }
    
        &__map {
            height: 300px;
        }
    }

    a[href^="http://maps.google.com/maps"]{display:none !important}
    a[href^="https://maps.google.com/maps"]{display:none !important}

    .gmnoprint a, .gmnoprint span, .gm-style-cc {
        display:none;
    }
    .gmnoprint div {
        background:none !important;
    }
    
    @media only screen and (max-width: 519px) {    
        &__wrapper {
            padding: 0;
        }
    }
    
    @media only screen and (max-width: 429px) {
        &__container {
            margin-top: 124px;
        }
    }
    
    @media only screen and (max-width: 400px) {
        &__page {
            font-size: rem(10);
        }
    }
    
    @media only screen and (max-width: 375px) {
        &__address {
            li {
                font-size: rem(12);
                line-height: rem(24);
            }
        }
    
        &__phone {
            font-size: rem(14);
            line-height: rem(32);
            letter-spacing: rem(2.8);
        }
    }
}