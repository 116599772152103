.particles-js {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 200;
}

@keyframes hideOpacity {
    100% {
        opacity: 0
    }
}

.home-hello-div {
    position: absolute;
    color: #fff !important;
    width: 400px;
    height: 67px;
    left: 50%;
    margin-left: -200px;
    top: 50%;
    text-align:center;
    margin-top: -33px;
    z-index: 300;
    filter: alpha(enabled=false);
    opacity: 0.7;
    transition-property: opacity;
    transition-duration: .4s;
    transition-timing-function: ease-out;
    animation: hideOpacity 2s ease-in  75s forwards;
}

.home-hello-text {
    font-size: rem(40);
    line-height: rem(40);
    color: #fff;
    font-family: 'RobotoMono', monospace;
    font-weight: 100;
}

.hero {
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background: #000;
    overflow: hidden;
    z-index: 100;
    
    &__slider {
        width: 100%;
        height: 100vh;
        position: absolute;
        left: 0;
        top: 0;
        overflow: hidden;
    }

    @keyframes imageAnimation {
        0% {
            opacity: 0;
            animation-timing-function: ease-in
        }
        8% {
            opacity: 1;
            transform: scale(1.05);
            animation-timing-function: ease-out
        }
        17% {
            opacity: 1;
            transform: scale(1.1);
        }
        20% {
            opacity: 0;
            transform: scale(1.1);
        }
        100% {
            opacity: 0;
        }
    }
    
    &__slide {
        width: 100%;
        height: 100vh;
        position: absolute;
        top: 0;
        left: 0;
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        overflow: hidden;
        opacity: 0;
        animation: imageAnimation 75s linear infinite;
        z-index: 100;

        &:first-child {
            animation-delay: 0s;
        }

        &:nth-child(2) {
            animation-delay: 5s;
        }

        &:nth-child(3) {
            animation-delay: 10s;
        }

        &:nth-child(4) {
            animation-delay: 15s;
        }

        &:nth-child(5) {
            animation-delay: 20s;
        }

        &:nth-child(6) {
            animation-delay: 25s;
        }

        &:nth-child(7) {
            animation-delay: 30s;
        }

        &:nth-child(8) {
            animation-delay: 35s;
        }

        &:nth-child(9) {
            animation-delay: 40s;
        }

        &:nth-child(10) {
            animation-delay: 45s;
        }

        &:nth-child(11) {
            animation-delay: 50s;
        }

        &:nth-child(12) {
            animation-delay: 55s;
        }

        &:nth-child(13) {
            animation-delay: 60s;
        }

        &:nth-child(14) {
            animation-delay: 65s;
        }
    }
}

.about-section  {
    width: 100%;
    background: #fff;
    margin: 110px auto;
    padding: 0 30px;
    transition: 0.3s;
    text-align: center;


    &__headline {
        font-family: $FuturaDemic;
        font-size: rem(36);
        font-weight: bold;
        line-height: rem(52);
        max-width: 868px;
        margin: 0 auto;
        opacity: 1;
        transform: translateY(0);
        transition: 1.3s ease-out 0.3s;
    }

    &__text {
        font-family: $HurmeGeometricSans1-Rg;
        font-size: rem(22);
        line-height: rem(28);
        margin-top: 4px;
    }

    &.animate-visible {

        .about-section__text {
            opacity: 0;
            transform: translateY(30px);
        }
    }
}

.build-section {
    @include container-full();
    margin-bottom: 240px;
    transition: 0.3s;

    &__row {
        @include row-flex();
    }

    &__about-us {
        @include col();
        @include size(6);
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        padding: 140px 60px 260px 60px;
        background: $darkest;
        color: #fff;
        transition: 0.3s;
    }

    &__wrapper {
        
        &.animate-visible {

            h2, .build-section__aboutBtn {
                opacity: 0;
                transform: translateX(-36px);
            }
    
            p {
                opacity: 0;
                transform: translateX(36px);
            }
        }
    }

    &__h2 {
        font-family: $HurmeGeometricSans1-Rg;
        text-transform: uppercase;
        font-weight: bold;
        font-size: rem(52);
        line-height: rem(52);
        margin-bottom: 44px;
        transition: 1.5s ease-out .3s;
    }

    &__text {
        display: flex;
        align-items: baseline;
        transition: 0.3s;
    }

    &__p {
        font-family: $HurmeGeometricSans1-Rg;
        font-size: rem(18);
        font-weight: normal;
        line-height: rem(32);
        letter-spacing: rem(0.6);
        color: #bea98a;
        margin-right: 80px;
        margin-left: 48px;
        max-width: 512px;
        transition: 1.5s ease-out .3s;
    }

    b {
        font-family: $HurmeGeometricSans1-SemiBold;
    }

    .white-border {
        transform: translateY(-6px);
    }

    &__aboutBtn {
        display: inline-block;
        border: 1px solid #fff;
        padding: 18px;
        width: 235px;
        margin-top: 44px;
        font-family: $HurmeGeometricSans1-Rg;
        font-size: rem(14);
        font-weight: normal;
        text-transform: uppercase;
        letter-spacing: rem(2.8);
        text-align: center;
        transition: 0.3s;

        &:hover {
            background: #fff;
            color: #000;
        }
    }

    &__experience {
        @include col();
        @include size(6);
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        transition: 0.3s;
    }

    .flex-col {
        flex: 1;
    }

    &__background {
        display: flex;
        flex: 1;
        align-items: center;
        justify-content: center;
        position: relative;
        background: url('../img/experience-bg.jpg') no-repeat center;
        background-size: cover;
        text-align: center;

        &:before {
            content: '';
            display: block;
            position: absolute;
            width: 100%;
            height: 100%;
            background: #000;
            opacity: 0.68;
        }
    }

    &__img {
        transform: translateY(40px);
    }

    &__projectsBtn {
        background: #fff;
        padding: 65px 12px 55px 12px;
        font-family: $HurmeGeometricSans1-Bold;
        font-size: rem(16);
        font-weight: bold;
        letter-spacing: rem(2.7);
        text-transform: uppercase;
        text-align: center;
        color: $darkest;
        cursor: pointer;

        a {
            display: inline-block;
            position: relative;
            transition: transform .3s;
        }

        span {
            display: inline-block;
            position: absolute;
            left: -70px;
            top: -2px;
            padding-right: 25px;
        }

        .arrow-icon {
            max-width: 0;
            opacity: 0;
            transition: opacity .2s ease;
        }

        &:hover {
            a {
                transform: translateX(20px);
            }

            .arrow-icon {
                max-width: 40px;
                opacity: 1;
            }
        }
    }
}

.home {
    .values {
        margin-bottom: 160px;
    }
}

@media only screen and (max-width: 1135px) {
    .build-section {
        margin-bottom: 100px;

        &__p {
            margin-right: 0;
        }

        &__row {
            flex-direction: column;
        }

        &__about-us {
            width: 100%;
            padding-bottom: 148px;
            align-items: center;
        }

        &__experience {
            width: 100%;
        }
        
        &__background {
            padding: 120px 36px;
        }

       &__img {
        transform: translateY(0px);
       }
    }
}

@media only screen and (max-width: 1119px) {
    .home {
        .values {
            margin-bottom: 100px;
        }
    }
}

@media only screen and (max-width: 768px) {
    .about-section {
        &__headline {
            font-size: rem(28);
            line-height: rem(48);
        }

        &__text {
            font-size: rem(20);
        }
    }
}

@media only screen and (max-width: 649px) {
    .home-hello-text {
        font-size: rem(34);
        line-height: rem(34);
    }

    .about-section {
        margin: 80px 0;
        padding: 0 36px;

        &__headline {
            font-size: rem(24);
            line-height: rem(40);
        }

        &__text {
            font-size: rem(16);
            line-height: rem(24);
        }
    }   
}

@media only screen and (max-width: 479px) {
    .build-section {

        &__about-us {
            padding: 96px 60px;
        }

        &__h2  {
            font-size: rem(44);
        }

        &__p {
            font-size: rem(14);
            line-height: rem(30);
            margin-left: 30px;
        }

        &__aboutBtn {
            margin-top: 48px;
        }
    }
}

@media only screen and (max-width: 449px) {
    .home-hello-text {
        font-size: rem(32);
        line-height: rem(32);
    }

    .about-section {
        margin: 60px 0;
        padding: 0 36px;

        &__headline {
            font-size: rem(22);
            line-height: rem(34);
        }

        &__text {
            font-size: rem(14);
            line-height: rem(22);
            margin-top: 0;
        }
    }

    .build-section {

        &__about-us {
            padding-left: 46px;
            padding-right: 46px;
        }
    }
}

@media only screen and (max-width: 415px) {
    .build-section {

        &__about-us {
            padding-left: 36px;
            padding-right: 36px;
        }

        &__h2 {
            font-size: rem(32);
            line-height: rem(42);
        }

        &__p {
            font-size: rem(12);
            line-height: rem(24);
        }

        &__aboutBtn {
            font-size: rem(12);
            letter-spacing: rem(2.4);
            width: 180px;
            padding: 14px;
        }
    }
}

@media only screen and (max-width: 399px) {
    .about-section {
        margin: 40px 0;
        padding: 0 36px;

        &__headline {
            font-size: rem(20);
            line-height: rem(32);
        }
    }   
}