.our-partners {
    max-width: 1478px;
    margin: 0 auto;
    margin-top: 180px;
    padding: 140px 140px 218px 140px;
    position: relative;
    color: #fff;
    background: url('../img/our-partners-bg.jpg') no-repeat;
    background-position: center;
    background-size: cover;
    transition: 0.3s;

    &__row {
        display: flex;
        width: 100%;
        height: 100%;
    }

    &__h2 {
        font-family: $HurmeGeometricSans1-Rg;
        font-size: rem(42);
        font-weight: bold;
        line-height: rem(62);
        margin-right: 110px;
        transition: 0.3s;
    }

    &__text {
        max-width: 760px;
        font-family: $HurmeGeometricSans1-Rg;
        font-size: rem(22);
        font-weight: normal;
        line-height: rem(42);
        letter-spacing: rem(0.88);
        color: #c4c4c3;
        transition: 0.3s;
    }

    &__list {
        margin-top: 66px;

        li {
            display: inline-block;
            margin-right: 40px;
        }

        a {
            font-family: $HurmeGeometricSans1-Rg;
            font-size: rem(32);
            font-weight: normal;
            line-height: rem(52);
            letter-spacing: rem(1.28);
            border-bottom: 0px solid transparent;
            transition: .3s ease-out;
    
            &:hover {
                border-bottom: 2px solid #fff;
            }
        }
    }

    &__whiteBox {
        position: absolute;
        bottom: 0;
        right: 0;
        height: 135px;
        width: 650px;
        background:#fff;
        transition: 0.3s;
    }

    @media only screen and (max-width: 1139px) {
        &__whiteBox {
            height: 120px;
            width: 500px;
        }
    
        &__list {
    
            a {
                font-size: rem(28);
            }
        }
    
        &__text {
            font-size: rem(20);
        }  
    }
    
    @media only screen and (max-width: 1079px) {
        & {
            padding-left: 80px;
            padding-right: 80px;
        }
    }
    
    @media only screen and (max-width: 849px) {
        & {
            padding-left: 60px;
            padding-right: 60px;
        }
    }
    
    @media only screen and (max-width: 769px) {
        & {
            padding-top: 80px;
            padding-bottom: 190px;
        }
    
        &__row {
            flex-direction: column;
        }
    
        &__h2 {
            max-width: 260px;
            margin-right: 0;
        }
    
        &__text {
            margin-top: 40px;
        }
    
        &__whiteBox {
            width: 50%;
            height: 100px;
        }
    }
    
    @media only screen and (max-width: 539px) {
        &__h2 {
            max-width: 200px;
            font-size: rem(32);
            line-height: rem(42);
        }
    
        &__text {
            margin-top: 28px;
            font-size: rem(16);
            line-height: rem(32);
            letter-spacing: rem(0.64);
        }
    
        &__list {
    
            li {
                margin-right: 30px;
            }
    
            a {
                font-size: rem(20);
                line-height: rem(42);
                letter-spacing: rem(0.8);
            }
        }
    }
    
    @media only screen and (max-width: 429px) {
        & {
            padding-left: 36px;
            padding-right: 36px;
            padding-bottom: 120px;
        }
    
        &__h2 {
            max-width: 100%;
        }
    
        &__list {
            margin-top: 54px;
    
            li {
                margin-right: 20px;
            }
        }
    
        &__whiteBox {
            height: 74px;
        }
    
        .white-border{
            display: block;
            margin-top: 30px;
        }
    }
}
