.tag {

    &__wrapper {
        display: flex;
        flex-direction: column;
        max-width: 1688px;
        padding: 0 36px;
        margin: 0px auto;
        transition: 0.3s;
    }

    &__row {
        display: flex;
    }

    &__heading {
        width: 100%;
    }

    &__nav {
        width: 100%;
        margin-left: 90px;
        padding-right: 6px;
    }

    &__h1 {
        font-family: $PlayfairDisplay-Bold;   
        font-size: rem(26);
        line-height: rem(58);
        font-weight: bold;
        margin-top: 14px;
        margin-bottom: 2px;
        transition: 0.3s;
        opacity: 1;
        transform: translateY(0);
        transition: transform 1s ease-out, opacity 1s ease-out;
    }

    &__h3 {
        display: inline-block;
        font-family: $Museosanscyrl;
        font-size: rem(16);
        letter-spacing: rem(3.2);
        color: #000;
        text-transform: uppercase;
        opacity: 1;
        transform: translateY(0);
        transition: transform 1s ease-out 0.6s, opacity 1s ease-out 0.6s;
    }

    &__backArrow {
        display: flex;
        margin-top: 54px;
        opacity: 1;
        transform: translateY(0);
        transition: 0.5s ease-out 1s;

        svg {
            transition: .3s;
        }

        svg:hover {
            transform: translateX(-10px);
        }
    }

    &__breadcrumbs {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        margin-bottom: -18px;
        padding-right: 10px;
        opacity: 1;
        transform: translateY(0);
        transition: transform 1s ease-out 0.6s, opacity 1s ease-out 0.6s;
    }

    &__pages {
        display: flex;
        transition: 0.3s;

        li {
            position: relative;
            margin-right: 30px;

            a {
                transition: .3s ease-out;
                border-bottom: 0px solid transparent;
            }

            a:hover {
                border-bottom: 1px solid #000;
            }

            &:nth-child(1n):after {
                content: '/';
                display: block;
                position: absolute;
                top: 0px;
                left: -16px;
            }

            &:first-child:after {
                content: '';
                display: none;
            }

            &:last-child {
                margin-right: 0;
            }
        }

    }

    &__back {
        opacity: 0;
        transition: 0.3s;

        &:hover {
            transform: translateX(-10px);
        }
    }


    &__page {
        font-family: $HurmeGeometricSans1-Rg;
        font-size: rem(11);
        font-weight: normal;
        letter-spacing: rem(0.77);
        text-transform: uppercase;
        color: #071110;
        line-height: rem(20);
    }

    &__page--active {
        font-family: $HurmeGeometricSans1-Bold;
        font-weight: bold;
        line-height: rem(21);
    }

    &__result {
        margin-top: 10px;
        margin-bottom: 30px;
        font-family: $HurmeGeometricSans1-Rg;
        font-size: rem(11);
        font-weight: normal;
        line-height: rem(22);
        letter-spacing: rem(0.77);
        text-transform: uppercase;
        color: #071110;
        transition: 0.3s;
    }

    &__list {
        display: flex;
        flex-direction: column;
        max-width: 1688px;
        padding: 0 36px;
        margin: 0 auto 0 auto;
        transition: 0.3s;
    }

    &__name {
        display: flex;
        margin-top: 35px;
        margin-bottom: 60px;
        font-family: $Museosanscyrl;
        font-size: rem(14);
        letter-spacing: rem(2.8);
        text-transform: uppercase;

        svg {
            transition: 0.3s;
            margin-left: 12px;
            margin-top: -2px;
        }

        .prefix__cls-1 {
            transition: .3s;
        }

        &:hover {
            svg {
                transform: translateX(14px);
            }

            .prefix__cls-1 {
                stroke: #000;
            }
        }

    }

    &__item {
        &:last-child .tag__name {
            margin-bottom: 20px;
        }
    }

    &__home {
        display: flex;
        margin-bottom: 40px;
        justify-content: center;
    }

    &.animate-visible {
        
        .tag__h1 {
            opacity: 0;
            transform: translateY(30px);
        }

        .tag__h3 {
            opacity: 0;
        }

        .tag__backArrow {
            opacity: 0;
        }

        .tag__breadcrumbs {
            opacity: 0;
        }
    }

    @media only screen and (max-width: 1490px) {
        &__wrapper {
            max-width: 1412px;
        }
    }
    
    @media only screen and (max-width: 1110px) {
        &__wrapper {
            padding: 0 50px;
        }
    
        &__nav {
            margin-left: 60px;
            padding-right: 0;
        }
    }
    
    @media only screen and (max-width: 960px) {
        &__backArrow {
            display: none;    
        }
    
        &__nav {
            margin-left: 0;
        }
    
        &__breadcrumbs {
            margin-bottom: 60px;
        }
    
        &__back {
            opacity: 1;
            margin-top: 2px;
        }
    }
    
    @media only screen and (max-width: 720px) {
        &__list {
            padding: 0 16px;
        }
    
        &__name {
            margin-bottom: 50px;
        }
    
        .tag__home {
            margin-top: 40px;
        }
    }
    
    @media only screen and (max-width: 540px) {
        &__wrapper {
            margin-bottom: 0;
        }
        
        &__h3 {
            font-size: rem(14);
        }
    
        &__name {
            font-size: rem(12);
    
            svg {
                margin-left: 16px;
            }
        }
    }
    
    @media only screen and (max-width: 519px) {
        &__breadcrumbs {
            padding-right: 0;
        }
    }
    
    @media only screen and (max-width: 429px) {    
        &__back {
    
            svg {
                transform: translateX(-16px);
            }
        }
    
        &__wrapper {
            padding: 0 36px;
        }
    
        &__name {
            margin-top: 25px;
            margin-bottom: 35px;
        }
    }
    
    @media only screen and (max-width: 400px) {
        &__page {
            font-size: rem(10);
        }
    }
    
    @media only screen and (max-width: 330px) {
        &__wrapper {
            padding: 0 24px;
        }
    
        &__back {
            svg {
                transform: translateX(-4px);
            }
        }
    }
}
