.project {

    &__wrapper {
        max-width: 1688px;
        padding: 0 36px;
        margin: 0px auto;
        display: flex;
        flex-direction: column;
        transition: 0.3s;
    }

    &__row {
        display: flex;
    }

    &__heading {
        width: 100%;
    }

    &__nav {
        width: 100%;
        margin-left: 90px;
    }

    &__h1 {
        font-family: $HurmeGeometricSans1-Rg;   
        font-size: rem(26);
        line-height: rem(58);
        font-weight: normal;
        max-width: 930px;
        margin-top: 14px;
        margin-bottom: 2px;
        opacity: 1;
        transform: translateY(0);
        transition: transform 1s ease-out, opacity 1s ease-out;
    }

    &__h3 {
        font-family: $HurmeGeometricSans1-Rg;
        font-size: rem(16);
        font-weight: normal;
        letter-spacing: rem(3.2);
        text-transform: uppercase;
        display: inline-block;
        opacity: 1;
        transform: translateY(0);
        transition: transform 1s ease-out 0.6s, opacity 1s ease-out 0.6s;
    }

    &__p {
        font-family: $HurmeGeometricSans1-Rg;
        font-size: rem(16);
        line-height: rem(32);
        font-weight: normal;
        letter-spacing: rem(1.12);
        max-width: 788px;
        color: #4D4D4D;
        opacity: 1;
        transform: translateY(0);
        transition: transform 0.8s ease-out 0.3s, opacity 0.8s ease-out 0.3s;
    }

    &__backArrow {
        display: flex;
        margin-top: 54px;
        opacity: 1;
        transform: translateY(0);
        transition: 0.5s ease-out 1s;

        svg {
            transition: .3s;
        }

        svg:hover {
            transform: translateX(-10px);
        }
    }

    &__breadcrumbs {
        display: flex;
        flex-wrap: wrap;
        flex-direction: column-reverse;
        justify-content: space-between;
        flex-direction: row;
        padding-right: 6px;
        opacity: 1;
        transform: translateY(0);
        transition: transform 1s ease-out 0.6s, opacity 1s ease-out 0.6s;
    }

    .small-arrow {
        opacity: 0;
        transition: 0.3s;
    }

    &__pages {
        display: flex;

        li {
            position: relative;
            margin-right: 30px;

            a {
                transition: .3s ease-out;
                border-bottom: 0px solid transparent;
            }

            a:hover {
                border-bottom: 1px solid #000;
            }

            &:nth-child(1n):after {
                content: '/';
                display: block;
                position: absolute;
                top: 0px;
                left: -16px;
            }

            &:first-child:after {
                content: '';
                display: none;
            }

            &:last-child {
                margin-right: 0;
            }
        }

    }

    &__page {
        font-family: $HurmeGeometricSans1-Rg;
        font-size: rem(11);
        font-weight: normal;
        letter-spacing: rem(0.77);
        text-transform: uppercase;
        color: #071110;
        line-height: rem(20);
    }

    &__page--active {
        font-family: $HurmeGeometricSans1-Bold;
        font-weight: bold;
        line-height: rem(21);
    }

    &__black-border {
        display: inline-block;
        width: 44px;
        min-width: 44px;
        margin-bottom: 4px;
        border-bottom: 1px solid #000;
        transition: opacity, width 0.3s;
        margin-bottom: 10px;
    }

    &__info {
        margin-top: -20px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

    &__square {
        display: flex;
    }

    &__tags {
        display: flex;
        align-items: flex-start;
        margin-top: 30px;

        .project__black-border {
            margin-top: 8px;
        }
    }

    &__filter {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        font-family: $HurmeGeometricSans1-Rg;
        font-size: rem(10);
        font-weight: normal;
        letter-spacing: rem(2.8);
        text-transform: uppercase;
        color: #808080;

        transition: transform 1s ease-out 0.6s, opacity 1s ease-out 0.6s;

        a {
            margin-left: 10px;
            transition: color 0.4s;
        }

        span {
            transition: transform 0.4s;
        }

        li {
            line-height: rem(40);
            margin-right: 32px;
        }

        li:first-child span {
            margin-left: 0;
        }

        li:hover {
            span {
                transform: rotate(-45deg);
                border-color: $orange;
            }

            a {
                color: $orange;
            }
        }
    }

    // &__tagsList {
    //     width: 100%;
    //     display: flex;
    //     flex-direction: row;
    //     justify-content: space-between;
    //     flex-wrap: wrap;

    //     li {
    //         margin-bottom: 16px;
    //         margin-right: 20px;
    //     }

    //     a {
    //         font-family: $HurmeGeometricSans1-Rg;
    //         font-size: rem(10);
    //         line-height-step: rem(32);
    //         letter-spacing: rem(1);
    //         text-transform: uppercase;
    //         color: #373736;
    //         border-bottom: 1px solid transparent;
    //         transition: 0.3s;

    //         &:hover {
    //             border-bottom: 1px solid #373736;
    //         }
    //     }
    // }

    &__social {
        display: flex;

        li {
            margin-left: 30px;
            transition: margin 0.3s;

            &:first-child {
                margin-left: 46px;
            }
        }

        .social-icon {
            transition: fill .4s ease;
        }

        a:hover {

            .social-icon {
                fill: $orange;
            }
        }

    }

    &__facebook {
        margin-left: 46px;
    }

    &__imgList {
        max-width: 1688px;
        padding: 0 36px;
        margin: 30px auto 0 auto;
    }

    &__landscape {
        margin-bottom: 40px;
        transition: 0.3s;
    }
 
    &__portrait {
        display: flex;
        justify-content: space-between;
        margin-bottom: 40px;
        height: 760px;
        transition: 0.3s;
        
        a {
            width: 50%;
            
            &:first-child {
                margin-right: 40px;
                transition: margin 0.3s;
            }
        }

        img {
            display: none;
            width: 100%;
        }
    }

    &__pagination {
        max-width: 1688px;
        width: 100%;
        padding: 0 36px;
        display: flex;
        justify-content: space-between;
        font-family: $Museosanscyrl;
        font-size: rem(14);
        font-weight: normal;
        letter-spacing: rem(2.8);
        text-transform: uppercase;
        margin: 60px auto 80px auto;
        
        a {
            display: flex;
            transition: 0.3s;
        }

        svg {
            transition: transform 0.3s;
        }
    }

    &__previous {
        display: flex;
        @include col();
        @include size(4);

        span {
            display: block;
            margin-top: 2px;
            margin-left: 36px;
            transition: font-size 0.3s;
        }

        a:hover {
            
            svg {
                transform: translateX(-10px);
            }
        }
    }

    &__next {
        display: flex;
        @include col();
        @include size(4);
        justify-content: flex-end;

        span {
            display: block;
            margin-top: 2px;
            margin-right: 36px;
            transition: font-size 0.3s;
        }

        a:hover {
            
            svg {
                transform: translateX(10px);
            }
        }
    }

    &__home {
        display: flex;
        justify-content: center;
        @include col();
        @include size(4);
    }

    &.animate-visible {

        .project__h3 {
            opacity: 0;
        }

        .project__p {
            opacity: 0;
            transform: translateY(30px);
        }
    }
    
    @media only screen and (max-width: 1490px) {
        &__wrapper {
            max-width: 1412px;
        }
    }
    
    @media only screen and (max-width: 1280px) {
        &__social {
    
            li {
                margin-left: 30px;
    
                &:first-child {
                    margin-left: 30px;
                }
            }
        }

        &__portrait {
            height: 600px;
        }

        &__backArrow {
            display: none;
        }
    
        &__p {
            max-width: 100%;
        }
    
        &__info {
            flex-direction: column;
        }
    
        &__nav {
            margin-left: 0;
        }
    
        &__breadcrumbs {
            margin-bottom: 90px;
    
            svg {
                margin-top: 2px;
                transition: 0.3s;
    
                &:hover {
                    transform: translateX(-10px);
                }
            }
    
            .small-arrow {
                opacity: 1;
            }
        }
    
        &__landscape {
            margin-bottom: 36px;
        }
    
        .project__portrait a:first-child {
            margin-right: 36px;
        }
    }
    
    @media only screen and (max-width: 1110px) {
        &__wrapper {
            padding: 0 50px;
        }

        &__portrait {
            height: 400px;
        }
    }

    &.animate-visible {

        .project__h1 {
            opacity: 0;
            transform: translateY(30px);
        }

        .project__breadcrumbs {
            opacity: 0;
        }

        .project__p {
            opacity: 0;
            transform: translateY(30px);
        }

        .project__backArrow {
            opacity: 0;
        }
    }

    @media only screen and (max-width: 1000px) {

        &__filter {
            justify-content: flex-start;
            width: 100%;

            span {
                width: 0;
                margin: 0;
                line-height: 40px;
            }
    
            a {
                margin-left: 0;
            }
        }
    }

    @media only screen and (max-width: 720px) {
        &__portrait {
            height: 340px;
        }

        &__imgList {
            padding: 0 16px;
        }
    
        &__landscape {
            margin-bottom: 16px;
        }
    
        &__portrait {
            margin-bottom: 16px;
        }
    
        .project__portrait a:first-child {
            margin-right: 16px;
        }
    }
    
    @media only screen and (max-width: 590px) {

        &__portrait {
            height: auto;
            flex-direction: column;
            margin-bottom: 0;

            a {
                width: 100%;
                margin-bottom: 16px;
                background: none!important;
            }

            img {
                display: flex;
                width: 100%;
            }
        }
    
        &__pages {
            justify-content: flex-end;
        }

        &__tagsList {
            flex-direction: column;

            a {
                font-size: rem(10);
            }
        }
    }
    
    @media only screen and (max-width: 540px) {    
        &__h3 {
            font-size: rem(14);
        }
    
        &__p {
            font-size: rem(14);
            line-height: rem(32);
            letter-spacing: rem(0.48);
        }
    
        &__pagination {
            span {
                font-size: 0;
            }
        }

        &__page:nth-child(2) {
            display: none;
        }
    }
    
    @media only screen and (max-width: 519px) {

        &__breadcrumbs {
            padding-right: 0;
        }
    }

    @media only screen and (max-width: 475px) {
        &__tags {
            flex-wrap: wrap;

            ul {
                margin: 0 auto;
            }
        }
    }
    
    @media only screen and (max-width: 429px) {
        &__wrapper {
            padding: 0 36px;
        }

        &__p {
            font-size: rem(12);
            line-height: rem(32);
            letter-spacing: rem(0.48);
        }
    
        &__image {
            height: 300px;
        }
    }
    
    @media only screen and (max-width: 400px) {
        &__page {
            font-size: rem(10);
    
            &--active {
                line-height: rem(20);
            }
        }
    
        &__social li:first-child {
            margin-left: 22px;
        }
    
        &__landscape {
            margin-bottom: 8px;
        }
    
        &__portrait {
            margin-bottom: 0;
    
            a {
                margin-bottom: 8px;
            }
        }
    }
    
    @media only screen and (max-width: 320px) {
        &__wrapper {
            padding: 0 24px;
        }
    }
}