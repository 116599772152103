.instagram-widget {

    &__name {
        position: relative;
        margin-bottom: 24px;

        h4 {
            font-family: $HurmeGeometricSans1-Bold;
            font-weight: bold;
            font-size: rem(16);
            line-height: rem(24);
            letter-spacing: rem(1.6);
            color: #fff;
            text-transform: uppercase;
            margin-bottom: 0;
        }

        .white-border {
            position: absolute;
            top: 11px;
            left: -76px;
            opacity: 0.7;
        }
    }

    &__list {
        display: flex;
        width: 100%;
    }

    .instagram_gallery {
        display: flex;
    
        a {
            display: inline-block;
            height: 142px;
            width: 138px;
            margin-right: 10px;
            overflow: hidden;
            opacity: 1;
            transition: 0.8s ease-out;
    
            &:last-child {
                margin-right: 0;
            }
        }
    
        img {
            width: 100%;
            transition-property: transform;
            transition-duration: 0.3s;
    
            &:before {
                content: '';
                display: block;
                width: 100%;
                height: 100%;
                position: absolute;
                top: 0;
                left: 0;
                background: #000;
                opacity: 0;
                transition-property: opacity;
                transition-duration: 0.3s;
            }
    
            &:hover {
                transform: scale(1.1);
    
                &:before {
                    opacity: 0.3;
                }
            }
        }
    }

    @media only screen and (max-width: 1660px) {
        .white-border {
            position: static;
            margin-top: -18px;
        }

        &__name {
            position: static;
            display: flex;
            flex-direction: row-reverse;
            justify-content: flex-end;
            align-items: center;

            h4 {
                margin-left: 24px;
            }
        }
    }

    @media only screen and (max-width: 820px) {
        & {
            width: 100%;
        }

        .instagram_gallery a {
            width: 32%;
        }
    }

    @media only screen and (max-width: 790px) {
        .white-border {
            width: 36px;
            min-width: 36px;
        }
    }

    @media only screen and (max-width: 440px) {
        .instagram_gallery a {
            height: 100px;
        }
    }
}