.footer {
    width: 100%;
    padding: 80px 40px 85px 40px;
    background: $darkest;
    color: #fff;

    &__wrapper {
        max-width: 1340px;
        margin: 0 auto;
    }

    &__row {
        @include row-flex();
    }

    &__contact {
        @include row-flex();
        width: 100%;
        margin-bottom: 100px;
        justify-content: space-between;
        font-family: $HurmeGeometricSans1-Rg;
        font-weight: normal;
        font-size: rem(14);
        letter-spacing: rem(2.8);
        line-height: rem(32);
    }

    .white-border {
        border-bottom: 1px solid #fff;
    }

    &__phone {
        font-family: $HurmeGeometricSans1-Bold;
        font-size: rem(18);
        font-weight: bold;
        letter-spacing: rem(3.6);
        border-bottom: 0px solid transparent;
        transition: .3s ease-out;

        &:hover {
            border-bottom: 1px solid #fff;
        }
    }

    &__email {
        border-bottom: 0px solid transparent;
        transition: .3s ease-out;
        text-transform: uppercase;

        &:hover {
            border-bottom: 1px solid #fff;
        }
    }

    &__item {
        @include col();
        @include size(3);
        display: flex;
        align-items: start;

        .white-border {
            transform: translateY(15px);
        }

        ul {
            margin-left: 30px;
        }
    }

    &__social {
        display: flex;
        justify-content: center;;

        a:first-child {
            margin-right: 32px;
        }

        .social-icon {
            transition: all .4s ease;
        }

        a:hover {
            
            .social-icon {
                fill: #ef8120;
            }
        }
    }

    &__address {
        padding-left: 40px;

        a {
            border-bottom: 0px solid transparent;
            transition: .3s ease-out;

            &:hover {
                border-bottom: 1px solid #fff;
            }
        }        
    }

    &__logo {
        text-align: right;
        display: flex;
        justify-content: flex-end;

        a {
            display: inline-block;
            width: 208px;
            height: 68px;
            background: url('../img/logo/logo-white.png') no-repeat;
            background-size: contain;
            background-position-x: 10px;
        }
    }

    &__hiden-logo {
        display: none;
    }

    &__nav {
        @include row-flex();
        width: 100%;
        display: flex;
        justify-content: space-between;
        
        ul {
            display: flex;
            font-family: $HurmeGeometricSans1-Bold;
            font-size: rem(14);
            font-weight: bold;
            text-transform: uppercase;
            letter-spacing: rem(2.8);

            li {
                margin-right: 80px;

                &:last-child {
                    margin-right: 10px;
                }
            }

            a {
                border-bottom: 0px solid transparent;
                transition: .3s ease-out;

                &:hover {
                    border-bottom: 1px solid #fff;
                }
            }
        }
    }

    &__copyright {
        font-family: $HurmeGeometricSans1-Rg;
        font-size: rem(14);
        font-weight: normal;
        text-align: right;
        letter-spacing: rem(0.5);
        color: #808080;
    }

    .itt-logo {
        width: 200px;
        font-family: 'Open Sans', sans-serif;
        text-align: right;
        white-space: nowrap;

        h4 {
            font-size: 10px;
            line-height: 10px;
            margin-left: 6px;
            text-align: left;
            display: inline-block;
            color: #767676;
            vertical-align: top;
        }
    }
    
    .itt-dots {
        display: inline-block;
        font-size: 0;
        vertical-align: top;

        span {
            display: inline-block;
            width: 9px;
            height: 9px;
            border-radius: 50%;
            background: #fff;
            margin-left: 4px;
        }

        span:first-of-type {
            background: #55fd9b;
        }

        span:nth-child(2) {
            background: #49e5b9;
        }

        span:last-of-type {
            background: #42c7de;
        }
    }

    @media only screen and (max-width: 1139px) {
        & {
            padding: 64px 40px;
        }
    
        &__item {
            width: 50%;
            justify-content: center;
        }
    
        &__address {
            padding-left: 0;
        }
    
        &__nav {
            max-width: 80%;
            margin: 0 auto;
    
            ul {
                width: 100%;
                justify-content: space-between;
    
                li {
                    margin-right: 0px;
                }
            }
        }
    
        &__social {
            width: 100%;
            margin-top: 65px;
        }
    
        &__logo {
            display: none;
        }
    
        &__contact {
            margin-bottom: 65px;
        }
    
        &__copyright {
            width: 100%;
            line-height: rem(22);
            text-align: center;
        }

        .itt-logo {
            margin-top: 40px;
            width: 100%;
            text-align: center;
        }
    
        &__hiden-logo {
            display: block;
            margin: 70px auto 34px auto;
    
            a {
                display: inline-block;
                width: 208px;
                height: 68px;
                background: url('../img/logo/logo-white.png') no-repeat;
                background-size: contain;
            }
        }
    }
    
    @media only screen and (max-width: 699px) {
        &__item {
            width: 100%;
    
            ul {
                margin-left: 0;
                text-align: center;
            }
        }
    
        &__e-contact, &__address {
            display: block;
        }
    
        &__e-contact {
            margin-bottom: 48px;
        }
    
        &__phone {
            font-size: rem(16);
        }
    
        .white-border {
            transform: translateY(0);
            display: block;
            margin: 0px auto 24px auto;
        }
    
        &__keep {
            display: none;
        }
    }
    
    @media only screen and (max-width: 619px) {
        &__nav {
    
            ul {
                flex-direction: column;
                text-align: center;
            }
    
            li {
                margin-bottom: 36px;
    
                &:last-child {
                    margin-bottom: 0px;
                }
            }
        }
    }
    
    @media only screen and (max-width: 449px) {
        &__copyright {
            font-size: rem(12);
        }
    }
    
    @media only screen and (max-width: 339px) {
        .footer__hiden-logo {
            a {
                width: 160px;
            }
        }
    }
}
