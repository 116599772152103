.home-btn {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 20px;
    height: 16px;

    &__item {
        display: block;
        width: 9px;
        height: 7px;
        background: #000;
        transition: 0.3s;
    }

    &:hover {
        .home-btn__item {
            background: $orange;
        }        
    }
}