@font-face {
    font-family: 'HurmeGeometricSans1-Bold';
    font-display: swap;
    src: url('../fonts/HurmeGeometricSans1-Bold.woff2') format('woff2'), 
      url('../fonts/HurmeGeometricSans1-Bold.woff') format('woff');
}

@font-face {
    font-family: 'HurmeGeometricSans1-SemiBold';
    font-display: swap;
    src: url('../fonts/HurmeGeometricSans1-SemiBold.woff2') format('woff2'), 
      url('../fonts/HurmeGeometricSans1-SemiBold.woff') format('woff');
}

@font-face {
    font-family: 'HurmeGeometricSans1-Rg';
    font-display: swap;
    src: url('../fonts/HurmeGeometricSans1-Rg.woff2') format('woff2'), 
      url('../fonts/HurmeGeometricSans1-Rg.woff') format('woff');
}

@font-face {
    font-family: 'PlayfairDisplay-Bold';
    font-display: swap;
    src: url('../fonts/PlayfairDisplay-Bold.woff2') format('woff2'), 
      url('../fonts/PlayfairDisplay-Bold.woff') format('woff');
}

@font-face {
    font-family: 'DMSerifDisplay-Regular';
    font-display: swap;
    src: url('../fonts/DMSerifDisplay-Regular.woff2') format('woff2'), 
      url('../fonts/DMSerifDisplay-Regular.woff') format('woff');
}

@font-face {
    font-family: 'Museosanscyrl';
    font-display: swap;
    src: url('../fonts/Museosanscyrl500.woff2') format('woff2'), 
      url('../fonts/Museosanscyrl500.woff') format('woff');
}

@font-face {
    font-family: 'RobotoMono';
    font-display: swap;
    src: url('../fonts/RobotoMono-Thin.woff2') format('woff2'), 
      url('../fonts/RobotoMono-Thin.woff') format('woff');
}

@font-face {
    font-family: 'FuturaDemic';
    font-display: swap;
    src: url('../fonts/FuturaDemiC.woff2') format('woff2'), 
      url('../fonts/FuturaDemiC.woff') format('woff');
}

@font-face {
    font-family: 'Roboto-Thin';
    font-display: swap;
    src: url('../fonts/Roboto-Thin.woff2') format('woff2'), 
      url('../fonts/Roboto-Thin.woff') format('woff');
}

@font-face {
    font-family: 'Roboto-Medium';
    font-display: swap;
    src: url('../fonts/Roboto-Medium.woff2') format('woff2'), 
      url('../fonts/Roboto-Medium.woff') format('woff');
}