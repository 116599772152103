// Fonst
$HurmeGeometricSans1-Bold: 'HurmeGeometricSans1-Bold', Arial, sans-serif;
$HurmeGeometricSans1-SemiBold: 'HurmeGeometricSans1-SemiBold', Arial, sans-serif;
$HurmeGeometricSans1-Rg: 'HurmeGeometricSans1-Rg', Arial, sans-serif;
$Museosanscyrl: 'Museosanscyrl', Arial, sans-serif;
$DMSerifDisplay-Regular: 'DMSerifDisplay-Regular', Arial, sans-serif;
$PlayfairDisplay-Bold: 'PlayfairDisplay-Bold', Times, serif;
$Roboto-Thin: 'Roboto-Thin', Arial, sans-serif;
$Roboto-Medium: 'Roboto-Medium', Arial, sans-serif;
$FuturaDemic: 'FuturaDemic', Arial, sans-serif;

// Colors
$darkest: #202020;
$orange: #ef8120;