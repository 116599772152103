.about-us {

    &__wrapper {
        max-width: 1412px;
        padding: 0 36px;
        margin: 0 auto 30px auto;
        display: flex;
    }

    &__nav {
        width: 100%;
        margin-left: 90px;
        transition: 0.3s;
    }

    &__h3 {
        font-family: $HurmeGeometricSans1-Rg;
        font-size: rem(24);
        font-weight: normal;
        text-transform: uppercase;
        margin-bottom: 30px;
        padding-top: 40px;
    }

    &__p {
        font-family: $HurmeGeometricSans1-Rg;
        font-size: 1.125rem;
        line-height: 2rem;
        letter-spacing: 0.045rem;
        font-weight: normal;
        max-width: 1064px;
        color: #4D4D4D;
        opacity: 1;
        transform: translateY(0);
        transition: transform 0.8s ease-out 0.3s, opacity 0.8s ease-out 0.3s;
    }

    &__backArrow {
        display: flex;
        margin-top: 54px;
        opacity: 1;
        transform: translateY(0);
        transition: 0.5s ease-out 1s;

        svg {
            transition: .3s;
        }

        svg:hover {
            transform: translateX(-10px);
        }
    }

    &__breadcrumbs {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        padding-right: 6px;
        opacity: 1;
        transform: translateY(0);
        transition: transform 1s ease-out 0.6s, opacity 1s ease-out 0.6s;
    }

    &__pages {
        display: flex;
        transition: 0.3s;

        li {
            position: relative;
            margin-right: 30px;

            a {
                transition: .3s ease-out;
                border-bottom: 0px solid transparent;
            }

            a:hover {
                border-bottom: 1px solid #000;
            }

            &:nth-child(1n):after {
                content: '/';
                display: block;
                position: absolute;
                top: 0px;
                left: -16px;
            }

            &:first-child:after {
                content: '';
                display: none;
            }

            &:last-child {
                margin-right: 0;
            }
        }

    }

    &__page {
        font-family: $HurmeGeometricSans1-Rg;
        font-size: rem(11);
        font-weight: normal;
        letter-spacing: rem(0.77);
        text-transform: uppercase;
        color: #071110;
        line-height: rem(20);
    }

    &__page--active {
        font-family: $HurmeGeometricSans1-Bold;
        font-weight: bold;
        line-height: rem(21);
    }

    &__images {
        display: flex;
        flex-direction: row;
        max-width: 1412px;
        padding: 0 36px;
        margin: 0 auto 135px auto;
        height: 535px;
        transition: 0.3s;
    }

    &__left {
        height: 100%;
        width: 40%;
        background: url('../img/pages/about-us.html-us-1.jpg') no-repeat center;
        background-size: cover;
        margin-right: 4px;
    }

    &__right {
        height: 100%;
        width: 60%;
    }

    &__topRight {
        height: 265px;
        width: 100%;
        background: url('../img/pages/about-us.html-us-2.jpg') no-repeat center;
        background-size: cover;
    }

    &__bottom {
        display: flex;
        margin-top: 4px;
    }

    &__bottomLeft {
        height: 266px;
        width: 50%;
        background: url('../img/pages/about-us.html-us-3.jpg') no-repeat center;
        background-size: cover;
    }
    
    &__left, &__topRight, &__bottomLeft {
        opacity: 1;
        transform: translateY(0);
        transition: transform 1s 0.8s, opacity 0.6s 0.8s;
    }

    &__bottomRight {
        width: 50%;
        height: 266px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &__projectsBtn {
        padding: 22px 0;
        width: 238px;
        font-family: $HurmeGeometricSans1-Bold;
        font-size: rem(14);
        font-weight: bold;
        text-align: center;
        letter-spacing: rem(2.8);
        text-transform: uppercase;
        color: #fff;
        background: #000;
        transition: transform 1s 0.8s, opacity 0.6s 0.8s, padding 0.3s;

        span {
            position: absolute;
            opacity: 0;
            transition: transform 0.3s, opacity 0.2s;
            transform: translateY(1px) translateX(0px);
        }

        &:hover {
            padding-right: 34px;

            span {
                opacity: 1;
                transform: translateY(1px) translateX(10px);
            }
        }

        &--hidden {
            display: none;
        }
    }

    &__text {
        display: flex;
        justify-content: flex-end;
        max-width: 1316px;
        padding-right: 36px;
        margin: 0 auto 160px auto;
        font-family: $HurmeGeometricSans1-Rg;
        font-size: rem(16);
        line-height: rem(32);
        letter-spacing: rem(1.12);
        font-weight: normal;
        color: #636366;

        .white-border {
            margin: 0 auto;
            display: block;
            border-color: #000;
            position: absolute;
            left: -10px;
            top: 14px;
        }
    }

    &__textWrapper {
        display: flex;
        max-width: 1160px;
    }

    &__textItem {
        @include col();
        @include size(6);

        &:first-child {
            position: relative;
            padding-left: 100px;
        }

        &:last-child {
            padding-left: 78px;
        }
    }

    &.animate-visible {

        .about-us__breadcrumbs {
            opacity: 0;
        }

        .about-us__p {
            opacity: 0;
            transform: translateY(30px);
        }

        .about-us__backArrow {
            opacity: 0;
        }

        .about-us__left, .about-us__topRight, .about-us__bottomLeft, .about-us__projectsBtn {
            opacity: 0;
            transform: translateY(40px);
        }
    }

    @media only screen and (max-width: 1290px) {
        &__text {
            padding-left: 36px;
        }
    
        &__textItem {
            
            .white-border {
                left: 0px;
            }
        }
    }
    
    @media only screen and (max-width: 1110px) {
        &__wrapper {
            padding: 0 50px;
        }
    
        &__backArrow {
            display: none;
        }
    
        &__nav {
            margin-left: 0;
        }
    
        &__images {
            margin-bottom: 30px;
        }
    
        &__topRight {
            height: calc(50% - 4px);
        }
    
        &__bottom {
            width: 100%;
            height: 100%;   
        }
    
        &__bottomLeft {
            background: url('../img/pages/about-us.html-us-3-2x.jpg') no-repeat center;
            background-size: cover;
            width: 100%;
        }
    
        &__bottomRight {
            display: none;
        }
    
        &__projectsBtn--hidden {
            max-width: 244px;
            margin: 34px auto 80px auto;
            display: block;
            opacity: 1;
            transform: translateY(0);
            transition: transform 1s 0.8s, opacity 0.6s 0.8s, padding 0.3s;
        }
    }
    
    @media only screen and (max-width: 820px) {

        &__images {
            height: 370px;
        }
    
        &__bottomLeft {
            height: 50%;
        }
    
        &__bottomLeft {
            background: url('../img/pages/about-us.html-us-3.jpg') no-repeat center;
            background-size: cover;
        }
    
        &__textWrapper {
            flex-direction: column;
        }
    
        &__textItem {
            width: 100%;
    
            &:last-child {
                margin-top: 50px;
                padding-left: 100px;
            }
        }
    }
    
    @media only screen and (max-width: 590px) {
        &__breadcrumbs {
            flex-direction: column-reverse;
        }

        &__h3 {
            padding-top: 0;
        }
    
        &__pages {
            justify-content: flex-end;
            margin-bottom: 60px;
        }
    
        &__images {
            height: 220px;
        }
    }
    
    @media only screen and (max-width: 540px) {
    
        &__h3 {
            font-size: rem(14);
        }
        
        &__projectsBtn {
            font-size: rem(12);
            max-width: 200px;
            padding: 20px;
        }
    }

    @media only screen and (max-width: 429px) {
        &__p {
            font-size: rem(16);
        }
        &__wrapper {
            padding: 0 36px;
        }
    
        &__text {
            margin-bottom: 60px;
        }
    
        .our-partners {
            margin-top: 70px;
        }
    }
    
    @media only screen and (max-width: 400px) {
        &__page {
            font-size: rem(10);
        }
    
        &__left {
            width: 60%;
        }
    
        &__right {
            width: 40%;
        }
    
        &__images {
            height: 170px;
        }
    
        &__textItem:first-child, &__textItem:last-child {
            padding-left: 80px;
            font-size: rem(12);
            line-height: rem(24);
            letter-spacing: rem(0.48);
        }
    
        &__textItem:last-child {
            margin-top: 30px;
        }
    }

    @media only screen and (max-width: 375px) {
        &__h3 {
            font-size: rem(13);
        }
    }
    
    @media only screen and (max-width: 360px) {
        &__h3 {
            letter-spacing: rem(2);
        }
    }
    
    @media only screen and (max-width: 320px) {
        &__wrapper {
            padding: 0 24px;
        }
    }
}