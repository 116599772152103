.header {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    padding: 35px 27px;
    z-index: 830;

    &__nav {
        max-width: 2560px;
        margin: 0 auto;
        display: flex;
        justify-content: space-between;
    }

    &__left-side {
        display: flex;
    }

    &__logo {
        display: inline-block;
        width: 208px;
        height: 68px;
        margin-top: 14px;
        margin-right: 14px;
        background: url('../img/logo/logo-white.png') no-repeat;
        background-size: contain;
        transition: .4s;

        &--open {
            margin-right: 180px;
        }
    }

    &__social {
        display: flex;
        padding-top: 42px;
        transition: opacity .4s ease;
        opacity: 1;

        .social-icon {
            transition: fill .4s ease;
        }
        
        a:hover {
            
            .social-icon {
                fill: $orange;
            }
        }
    }

    &__facebook {
        margin-top: -1px;
        margin-left: 48px;
    }

    &__instagram {
        margin-left: 48px;
        transition: 0.3s;
    }


    // Menu

    &__menuBtn {
        width: 38px;
        margin: 0 auto;
        padding-top: 28px;
        padding-left: 28px;
        padding-right: 46px;
    
        &_icon {
            position: relative;
            width: 40px;
            height: 39px;
            border: 1px solid rgba(255,255, 255, .4);
            transition: border-color .5s ease;
            cursor: pointer;
    
            &:hover {
                border-color: #fff;
            }
        }
    
        &_line-1, &_line-2, &_line-3 {
            position: absolute;
            height: 1px;
            margin: 0;
            padding: 0;
            background: #fff;
            transition: opacity .3s ease-out;
    
        }
    
        &_line-1 {
            width: 14px;
            top: 12px;
            left: 13px;
        }
    
        &_line-2 {
            width: 24px;
            left: 8px;
            top: 18px;
        }
    
        &_line-3 {
            width: 14px;
            left: 13px;
            top: 24px;
        }
    
        &_line-4 {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 13px;
            width: 1px;
            height: 12px;
            opacity: 0;
            background: transparent;
            transition: top .3s ease-in-out .6s, background-color, opacity .3s ease-in-out .6s;
        }
        
        &_title {
            display: block;
            font-family: Arial, sans-serif;
            font-size: rem(10);
            text-transform: uppercase;
            text-align: center;
            padding-top: 14px;
            letter-spacing: rem(2);
            color: #fff;
            transition: opacity .3s ease-out;
            cursor: pointer;
        }
    }

    &__menu {
        position: fixed;
        width: 0px;
        top: 0;
        left: 0;
        background: rgba(0, 0, 0, 0);
        transition-duration: .8s;
        transition-timing-function: cubic-bezier(0.7, 0, .3, 1);
    
        .header__menuBtn {
            margin: 35px auto 0 auto;
            padding-left: 0;
            visibility: hidden;
            opacity: 0;
        }
    }

    &__wrapper {
        height: 100vh;
        padding: 0;
        opacity: 0;
    }

    &__list {
        text-align: center;
    }

    &__item {
        font-family: 'FuturaDemiC', Arial;
        font-size: rem(12);
        line-height: rem(56);
        letter-spacing: rem(2);
        text-transform: uppercase;
        color: #fff;
        height: rem(56);
        border-bottom: 1px solid #2e2e2e;
        transition: color .3s ease-out, letter-spacing .3s ease-out;
        cursor: pointer;

        &:hover {
            color: #EE7F1A;
            letter-spacing: rem(3);
        }
    }

    &__link {
        display: inline-block;
        width: 100%;
        opacity: 0;
        visibility: hidden;
        transition: opacity .3s ease-out .6s, visibility .3s ease-out .6s;
    }

    &__menu--open {
        display: block;
        width: 314px;
        background: rgba(0, 0, 0, 1);

        .header__wrapper {
            padding: 10px 30px 0;
        }
        
        .header__menuBtn {
            display: block;
            opacity: 1;
            visibility: visible;
            transition: .1s opacity .4s;
            margin: 35px auto 0 auto;
    
            .header__menuBtn_line-1, .header__menuBtn_line-2, .header__menuBtn_line-3 {
                background: #fff;
            }
    
            .header__menuBtn_title {
                color: transparent;
            }
        }
    
        .header__menuBtn_icon {
            border-color: rgba(255, 255, 255, 0.4);
    
            &:hover {
                border-color: rgba(255, 255, 255, 1);
            }
        }
    
        .header__menuBtn_line-2 {
            width: 14px;
            left: 13px;
        }
    
        .header__menuBtn_line-4 {
            top: 13px;
            background: #fff;
            opacity: 1;
        }
    
        .header__wrapper {
            opacity: 1;
        }
    
        .header__link {
            opacity: 1;
            visibility: visible;
        }
    }


    
    @media only screen and (max-width: 718px) {
        &__facebook {
            margin-left: 36px;
        }
    
        &__instagram {
            margin-left: 36px;
        }
    }
    
    @media only screen and (max-width: 578px) {
        &__social {
            opacity: 0;
            height: 0;
        }
    }
    
    @media only screen and (max-width: 519px) {
        & {
            padding: 35px 8px;
        }
    }

    @media only screen and (max-width: 345px) {
        .header__facebook {
            margin-left: 22px;
        }

        .header__instagram {
            margin-left: 18px;
        }
    }
    
    @media only screen and (max-width: 429px) {
        & {
            padding: 35px 10px 35px 20px;
        }

        .header__left-side {
            margin-left: 4px;
        }

        .header__menuBtn {
            padding-top: 0;
            padding-left: 0;
            padding-right: 0;

            &_icon {
                margin-top: -10px;
            }
        }

        .header__social {
            padding-top: 0;
        }
    
        .header__logo {
            width: 151px;
            height: 47px;
            margin-top: -14px;
            margin-right: 0;
        }
    }

    @media only screen and (max-width: 375px) {
        .header__facebook {
            margin-left: 28px;
        }

        .header__instagram {
            margin-left: 22px;
        }
    }
}
