.send-requestComplete {
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    z-index: 1100;
    background: rgba(0, 0, 0, .5);
    transition: .5s;
    opacity: 0;
    visibility: hidden;

    &--visible {
        opacity: 1;
        visibility: visible;
    }

    @media only screen and (max-width: 849px) {

        .send-requestModal {
            width: 90%;
            padding: 65px 20px;
        }

        h3 {
            font-size: rem(24);
        }
    }

    @media only screen and (max-width: 539px) {
        h3 {
            font-size: rem(20);
        }
    }
}

.send-requestModal {
    text-align: center;
    position: fixed;
    top: 50%;
    left: 50%;
    width: 624px;
    transform: translate(-50%, -50%);
    padding: 65px 40px 70px;
    background: #fff;

    h3 {
        font-family: Arial, sans-serif;
        font-size: rem(30);
        font-weight: 400;
        text-transform: uppercase;
        color: #141414;
        margin-bottom: 20px;
    }

    p {
        font-family: Arial, sans-serif;
        font-size: rem(13);
        line-height: rem(17);
        font-weight: 400;
        margin-bottom: 25px;
        color: #333;
    }
}

.send-request {

    &__button {
        justify-content: flex-end;
        position: fixed;
        bottom: 70px;
        left: -76px;
        width: 216px;
        background: #000;
        transform: rotate(-90deg);
        cursor: pointer;
        z-index: 800;
        -webkit-touch-callout: none;
        -webkit-user-select: none;
        -khtml-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
    }

    &__h6 {
        font-family: $HurmeGeometricSans1-Rg;
        font-size: rem(14);
        font-weight: normal;
        letter-spacing: rem(2.8);
        color: #fff;
        text-transform: uppercase;
        text-align: center;
        padding: 30px 0;
    }

    &__icon {
        margin-left: 10px;

        svg {
            transform: rotate(90deg);
        }
    }

    &__menu {
        position: fixed;
        top: 0;
        left: 0;
        width: 670px;
        height: 100%;
        background: #fff;
        box-shadow: 0 0 60px 0 rgba(0, 0, 0, 0.16);
        z-index: 1000;
        transition-duration: .8s;
        transition-timing-function: cubic-bezier(0.7, 0, .3, 1);
        transform: translateX(-100%);
        overflow-y: auto;

        &::-webkit-scrollbar {
            background: #fff;
            width: 1px;
        }

        &::-webkit-scrollbar-track {
            box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        }

        &::-webkit-scrollbar-thumb {
            background-color: #fff;
            outline: 1px solid #fff;
        }

        &--open {
            transform: translateX(0%);
        }
    }

    &__wrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 0 35px 0 75px;
        height: 100%;
        width: 100%;
        position: relative;
        overflow-y: auto;
        transition: 0.5s;

        &::-webkit-scrollbar {
            background: #fff;
            width: 1px;
        }

        &::-webkit-scrollbar-track {
            background: none;
        }

        &::-webkit-scrollbar-thumb {
            background-color: $orange;
            outline: 1px solid #fff;
        }
    }

    &__h5 {
        font-family: $Roboto-Medium;
        font-weight: normal;
        font-size: rem(18);
        line-height: rem(20);
        font-weight: 500;
        text-transform: uppercase;
        letter-spacing: rem(2);
        color: #000;
        margin-bottom: 95px;
    }

    &__title {
        font-family: $DMSerifDisplay-Regular;
        font-size: rem(42);
        font-weight: normal;
        line-height: rem(52);
        color: #000;
    }

    &__message {
        font-family: $HurmeGeometricSans1-Rg;
        font-size: rem(20);
        line-height: rem(33);
        letter-spacing: rem(0.8);
        max-width: 512px;
        margin-top: 54px;
        margin-bottom: 80px;
    }

    &__buttons {
        display: flex;
        flex-direction: row;
    }

    &__close, &__projects {
        font-family: $HurmeGeometricSans1-Bold;
        font-size: rem(20);
        font-weight: bold;
        letter-spacing: rem(4);
        cursor: pointer;
        text-transform: uppercase;
    }

    &__close {
        height: 80px;
        text-align: center;
        margin-right: 56px;
        width: 236px;
        background: #000;
        color: #fff;
        transition: 0.3s;
        outline: none;
        line-height: rem(80);

        span {
            position: absolute;

            transition: transform 0.3s, opacity 0.2s;
            opacity: 0;
        }

        &:hover {
            padding-right: 34px;

            span {
                opacity: 1;
                transform: translateX(10px);
            }
        }
    }

    &__projects {
        padding: 20px 0px 20px 0;
        height: auto;
        color: #000;
        line-height: rem(42);

        a {
            border-bottom: 2px solid transparent;
            cursor: pointer;
            transition: 0.3s;

            &:hover {
                border-bottom: 2px solid #000;
            }
        }
    }

    &__heading {
        display: flex;
        justify-content: space-between;
        position: relative;
    }

    @media only screen and (max-width: 1600px) {
        &__button {
            display: none;
        }
    }

    @media only screen and (max-width: 849px) {
        &__menu {
            width: 560px;
        }
    }

    @media only screen and (max-width: 718px) {
        
    }

    @media only screen and (max-width: 619px) {
        &__wrapper {
            padding: 0 35px;
        }

        &__menu {
            width: 510px;
        }
    }

    @media only screen and (max-width: 539px) {
        &__menu {
            width: 100%;
        }
    }

    @media only screen and (max-width: 375px) {
        &__wrapper {
            padding: 0 20px;
        }
    }
}
