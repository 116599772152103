.pre-footer {
    padding: 0 36px;
    transform: scale(1) translateY(0);
    transition: 1s ease-out;


    &__wrapper {
        max-width: 1616px;
        margin: 0 auto;
        background-image: linear-gradient(to bottom, rgba(32, 32, 32, 0.06), rgba(255, 255, 255, 0));
        text-align: center;
        padding: 98px 40px;
        transition: 0.3s;
    }

    &__row {
        @include row-flex();
        flex-direction: column;
        align-items: center;
    }

    &__text {
        max-width: 1064px;
        margin-bottom: 40px;
        font-family: $HurmeGeometricSans1-Rg;
        font-weight: normal;
        font-size: rem(32);
        line-height: rem(50);
        opacity: 1;
        transform: translateY(0);
        transition: 1s ease-out 1s;
    }

    &__slogon {
        font-family: $HurmeGeometricSans1-Bold;
        font-size: rem(18);
        font-weight: bold;
        letter-spacing: rem(3.6);
        text-transform: uppercase;
        color: #373736;
        opacity: 1;
        transform: translateY(0);
        transition: 1s ease-out 1s;

        span {
            font-family: sans-serif, Arial, Tahoma;
            font-weight: bold;
        }
    }

    &__sendBtn {
        position: relative;
        width: 237px;
        padding: 22px 10px;
        margin-top: 48px;
        background: #000;
        border: 1px solid transparent;
        font-family: $HurmeGeometricSans1-Rg;
        font-size: rem(14);
        font-weight: normal;
        text-transform: uppercase;
        letter-spacing: rem(2.8);
        color: #fff;
        cursor: pointer;
        -webkit-touch-callout: none;
        -webkit-user-select: none;
        -khtml-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        transition: opacity 1s 1s, transform 1s 1s, padding 0.3s;

        span {
            position: absolute;
            opacity: 0;
            transition: transform 0.3s, opacity 0.2s;
            transform: translateY(1px) translateX(0px);
        }

        &:hover {
            padding-right: 40px;

            span {
                opacity: 1;
                transform: translateY(1px) translateX(10px);
            }
        }
    }

    &.animate-visible {
        transform: scale(.9) translateY(50px);

        .pre-footer__text, .pre-footer__slogon, .pre-footer__sendBtn {
            opacity: 0;
            transform: translateY(30px);
        }
    }
    
    @media only screen and (max-width: 999px) {
        &__text {
            font-size: rem(28);
        }
    
        &__slogon {
            font-size: rem(16);
        }
    }
    
    @media only screen and (max-width: 699px) {
        &__text {
            font-size: rem(24);
        }
    }
    
    @media only screen and (max-width: 499px) {
        &__wrapper {
            padding: 86px 32px 88px 32px;
        }
    
        &__text {
            font-size: rem(18);
            line-height: rem(32);
            margin-bottom: 60px;
        }
    
        &__slogon {
            font-size: rem(14);
            line-height: rem(24);
            max-width: 180px;
            margin: 0 auto;
        }
    
        &__sendBtn {
            margin-top: 26px;
            padding: 21px 10px;
        }
    }
    
    @media only screen and (max-width: 365px) {
        &__wrapper {
            padding: 60px 22px;
        }
    }
    
    @media only screen and (max-width: 345px) {
        & {
            padding: 0;
        }
    
        &__wrapper {
            padding: 64px 22px;
        }
    }

    @media only screen and (max-height: 820px) {
        &__menu {
            max-width: 700px;
            width: 700px;
        }
    }
}